import { observable } from "mobx";
import { Boat } from "./Boat";
import { BullpenInspection } from "./BullpenInspection";
import { GrindInspection } from "./GrindInspection";
import NewModel from "./NewModel";
import { Cooler } from "./Cooler";

export class Bullpen extends NewModel {
  @observable id!: number;

  @observable status!: string;

  @observable is_utilized!: boolean;

  @observable bullpen_number!: string;

  @observable net_weight!: number;

  @observable tare_weight!: number;

  @observable pack_time!: string;

  @observable created_at!: Date;

  @observable updated_at!: Date;

  @observable boat!: Boat;

  @observable secondaryBoat!: Boat;

  @observable secondary_boat_weight?: number;

  @observable cooler!: Cooler;

  @observable bullpenInspections!: BullpenInspection[];

  @observable bullpenGroup!: GrindInspection;

  @observable reworkBullpen!: Bullpen;
}
