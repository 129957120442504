import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  LazyLoadedAuthContainer,
  LazyLoadedDashboardContainer,
} from "../containers";
import ProtectedRoute from "../guards/ProtectedRoute";

export const appRoutes = (
  <Switch>
    <Route path="/auth" component={LazyLoadedAuthContainer} />
    <ProtectedRoute path="/" component={LazyLoadedDashboardContainer} />
  </Switch>
);
