import NewStore from "./NewStore";
import { Goals } from "models/Goals";
import { action, observable } from "mobx";
import ApiRoutes from "routes/ApiRoutes";
import { GoalType } from "enums/GoalType";
import { JuiceSpeciesYield } from "models/dtos/JuiceSpeciesYieldDTO";
import { SpeciesYield } from "models/dtos/SpeciesYieldDTO";

export class GoalsStore extends NewStore<Goals> {
  private static _instance: GoalsStore;

  @observable goalsList: Goals[] = [];

  @observable goalByDate: Goals;

  @observable isFetchingList: boolean = false;

  @observable yieldReportURL: string = "";

  @observable yieldListByDateRange: {
    yieldData: SpeciesYield[];
    juiceYieldData: JuiceSpeciesYield[];
  };

  @observable sandReportUrl: string;

  constructor() {
    super();
    Goals._store = this;
  }

  static getInstance(): GoalsStore {
    if (!this._instance) {
      this._instance = new GoalsStore();
    }
    return this._instance;
  }

  @action
  async fetchGoals(force?: boolean, params?: { [key: string]: any }) {
    try {
      if (this.goalsList.length > 0 && !force) {
        return;
      }
      this.isFetchingList = true;
      const response = await this.apiService.get(ApiRoutes.goals.list, params);
      if (force) {
        this.goalsList = response.data;
      } else {
        this.goalsList.push(...response.data);
      }
    } finally {
      this.isFetchingList = false;
    }
  }

  @action
  async fetchGoalByDate(params: { [key: string]: any }) {
    this.goalByDate = undefined;
    const response = await this.apiService.get(
      ApiRoutes.goals.getByDate,
      params,
    );
    this.goalByDate = response.data;
  }

  @action
  async createGoals(body: { [key: string]: any }) {
    const response = await this.apiService.post(ApiRoutes.goals.list, body);
    let found = 0;
    this.goalsList.forEach((element, index) => {
      if (element.date === body.date) {
        this.goalsList[index] = response.data;
        found = 1;
      }
    });
    if (found === 0) {
      this.goalsList.push(response.data);
    }
  }

  @action
  async fetchGoalsByDates(params: { [key: string]: any }) {
    const response = await this.apiService.get(
      ApiRoutes.goals.getByDates,
      params,
    );
    this.goalsList = response.data;
  }

  @action
  async fetchYieldSpreadsheetByDateRange(params: { [key: string]: any }) {
    const response = await this.apiService.get(
      ApiRoutes.reports.getYieldSpreadsheet,
      params,
    );
    this.yieldReportURL = response.url;
  }

  @action
  async fetchYieldListByDateRange(params: { [key: string]: any }) {
    const response = await this.apiService.get(
      ApiRoutes.reports.getYieldList,
      params,
    );
    this.yieldListByDateRange = response.data;
  }

  @action
  async fetchSandReport(params: { [key: string]: any }) {
    const response = await this.apiService.get(
      ApiRoutes.reports.getSandSpreadsheet,
      params,
    );
    this.sandReportUrl = response.url;
  }

  @action
  async deleteGoal(id: number, goalType: GoalType, date: string) {
    const response = await this.apiService.delete(
      ApiRoutes.goals.deleteGoal(id),
      { type: goalType },
    );
    if (response["true"]) {
      const updatingGoalIndex = this.goalsList.findIndex(
        el => el.date === date,
      );
      let goal = this.goalsList[updatingGoalIndex];
      if (goalType === GoalType.BOAT) {
        const updatedBoatGoals = goal.boats.filter(el => el.id !== id);
        goal.boats = updatedBoatGoals;
      } else {
        const updatedCustomerGoals = goal.customers.filter(el => el.id !== id);
        goal.customers = updatedCustomerGoals;
      }
      this.goalsList.splice(updatingGoalIndex, 1, goal);
    }
  }
}
