import { action, computed, observable } from "mobx";
import NewStore from "./NewStore";
import { ModelList } from "models/ModelList";
import { ModelItem } from "models/ModelItem";
import { Customer } from "models/Customer";
import ApiRoutes from "routes/ApiRoutes";

export class CustomerStore extends NewStore<Customer> {
  private static _instance: CustomerStore;

  constructor() {
    super();
    Customer._store = this;
  }

  static getInstance(): CustomerStore {
    if (!this._instance) {
      this._instance = new CustomerStore();
    }
    return this._instance;
  }

  @observable customerList = new ModelList<Customer>(Customer);

  @observable customerItem = new ModelItem<Customer>(Customer);

  @computed get customers() {
    return this.customerList.items.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );
  }

  @computed get activeCustomers() {
    return this.customers.filter(customer => !customer.is_archived);
  }

  @computed get inActiveCustomers() {
    return this.customers.filter(customer => customer.is_archived);
  }

  @action
  async fetchCustomers(
    params?: { [key: string]: any },
    config?: { [key: string]: any },
  ) {
    return this.customerList.load(ApiRoutes.customers.list, params, config);
  }

  @action
  async createElement(body: { [key: string]: any }) {
    const response = await this.apiService.post(ApiRoutes.customers.list, body);
    const customer = Customer.fromJson(response.data) as Customer;
    this.customerList.appendItem(customer);
    return customer;
  }

  @action
  async updateElement(customer: Customer, body: { [key: string]: any }) {
    try {
      customer.setUpdating(true);
      const response = await this.apiService.put(
        ApiRoutes.customers.show(customer.id),
        body,
      );
      customer.setUpdating(false);
      customer.updateFromJson(response.data);
      return customer;
    } catch (e) {
      customer.setUpdating(false);
      throw e;
    }
  }

  @action
  async deleteElement(customer: Customer) {
    try {
      customer.setDeleting(true);
      await this.apiService.delete(ApiRoutes.customers.show(customer.id));
      customer.setDeleting(false);
      customer.delete();
    } catch (e) {
      customer.setDeleting(false);
      throw e;
    }
  }

  @action
  async fetchCustomerFromId(id: number) {
    return this.customerItem.load(ApiRoutes.customers.show(id));
  }
}
