import React from "react";

export const LazyLoadedDashboardContainer = React.lazy(() =>
  import("./DashboardContainer/DashboardContainer"),
);
export const LazyLoadedHomeContainer = React.lazy(() =>
  import("./HomeContainer/HomeContainer"),
);
export const LazyLoadedManagementContainer = React.lazy(() =>
  import("./ManagementContainer/ManagementContainer"),
);
export const LazyLoadedProfileContainer = React.lazy(() =>
  import("./ProfileContainer/ProfileContainer"),
);
export const LazyLoadedTodayProductionsContainer = React.lazy(() =>
  import("./TodayProductionsContainer/TodayProductionsContainer"),
);
export const LazyLoadedUsersContainer = React.lazy(() =>
  import("./UsersContainer/UsersContainer"),
);
export const LazyLoadedAuthContainer = React.lazy(() =>
  import("./AuthContainer"),
);
export const LazyLoadedInventoryContainer = React.lazy(() => 
  import("./InventoryContainer/InventoryContainer"),
);
