import { ShellStock } from "models/ShellStock";
import { action, computed, observable } from "mobx";
import { Dictionary } from "services/ApiService";
import { TrailerCondition } from "models/TrailerCondition";
import NewStore from "./NewStore";
import ApiRoutes from "routes/ApiRoutes";
import { ModelItem } from "models/ModelItem";
import { format } from "date-fns";
import { PaginatedModelList } from "models/PaginatedModelList";

export class ShellStockStore extends NewStore<ShellStock> {
  private static _instance: ShellStockStore;

  @observable shellStockItem = new ModelItem<ShellStock>(ShellStock);

  @observable shellStockList = new PaginatedModelList<ShellStock>(ShellStock);

  @observable trailerConditionsList: Array<TrailerCondition> = [];

  @observable isTrailerOptionsFetching: boolean = false;

  @observable detailPageId: number = 0;

  constructor() {
    super();
    ShellStock._store = this;
    this.searchFilterParam.received_date = format(new Date(), "yyyy-MM-dd");
  }

  static getInstance(): ShellStockStore {
    if (!this._instance) {
      this._instance = new ShellStockStore();
    }
    return this._instance;
  }

  @computed get shellStocks() {
    return this.shellStockList.items;
  }

  @action
  async fetchShellStocks(queryParams?: Dictionary<any>) {
    return this.shellStockList.load(ApiRoutes.shellstocks.list, queryParams);
  }

  @action
  async addShellStock(body: { [key: string]: any }) {
    const response = await this.apiService.post(
      ApiRoutes.shellstocks.list,
      body,
    );
    const stock = ShellStock.fromJson(response.data) as ShellStock;
    this.shellStockList.setItems([stock, ...this.shellStockList.items]);
    return stock;
  }

  @action
  async updateElement(shellStock: ShellStock, body: { [key: string]: any }) {
    try {
      shellStock.setUpdating(true);
      const response = await this.apiService.put(
        ApiRoutes.shellstocks.show(shellStock.id),
        body,
      );
      shellStock.setUpdating(false);
      shellStock.updateFromJson(response.data);
      return shellStock;
    } catch (e) {
      shellStock.setUpdating(false);
      throw e;
    }
  }

  @action
  async deleteElement(shellStock: ShellStock) {
    try {
      shellStock.setDeleting(true);
      await this.apiService.delete(ApiRoutes.shellstocks.show(shellStock.id));
      shellStock.setDeleting(false);
      shellStock.delete();
    } catch (e) {
      shellStock.setDeleting(false);
      throw e;
    }
  }

  @action
  async getShellStockById(id: number) {
    return this.shellStockItem.load(ApiRoutes.shellstocks.show(id));
  }

  @action
  async fetchTrailerConditionOptions() {
    try {
      if (this.trailerConditionsList.length > 1) {
        return;
      }
      this.isTrailerOptionsFetching = true;
      const response = await this.apiService.get(
        ApiRoutes.trailerConditions.options,
      );
      this.trailerConditionsList.push(...response.data);
      this.isTrailerOptionsFetching = false;
    } catch (e) {
      this.isTrailerOptionsFetching = false;
      throw e;
    }
  }

  @action
  async fetchSearchedQuery(query?: { [key: string]: any }) {
    this.searchFilterParam = { ...this.searchFilterParam, ...query };
    await this.fetchShellStocks(this.searchFilterParam);
    if (this.searchFilterParam.page) {
      delete this.searchFilterParam.page;
    }
  }

  @action
  setDetailPageId(id: number) {
    this.detailPageId = id;
  }

  @action
  getDetailPageId() {
    return this.detailPageId;
  }
}
