import NewStore from "./NewStore";
import { action, observable } from "mobx";
import { ModelItem } from "models/ModelItem";
import { ProductSummary } from "models/product-summary-report/ProductSummary";
import ApiRoutes from "routes/ApiRoutes";
import { Dictionary } from "services/ApiService";

export class ProductSummaryStore extends NewStore<ProductSummary> {
  private static _instance: ProductSummaryStore;

  @observable productSummaryItem = new ModelItem<ProductSummary>(
    ProductSummary,
  );

  constructor() {
    super();
    ProductSummary._store = this;
  }

  static getInstance(): ProductSummaryStore {
    if (!this._instance) {
      this._instance = new ProductSummaryStore();
    }
    return this._instance;
  }

  @action
  async fetchProductSummaryList(params?: Dictionary<any>) {
    try {
      return await this.productSummaryItem.load(
        ApiRoutes.productSummary.list,
        params,
        { forceRefresh: true },
      );
    } catch (e) {
      this.productSummaryItem.setItem(null);
      throw e;
    }
  }

  @action
  async updateProductSummary(id: number, body: Dictionary<any>) {
    const response = await this.apiService.put(
      ApiRoutes.productSummary.update(id),
      body,
    );
    const object = this.productSummaryItem.item;
    object.general = response.data;
    this.productSummaryItem.setItem(object);
  }

  @action
  async generateReport(params?: Dictionary<any>) {
    const response = await this.apiService.get(
      ApiRoutes.productSummary.generateReport,
      params,
    );
    return response;
  }
}
