import NewModel from "models/NewModel";
import { ProductSummaryStore } from "stores/ProductSummaryStore";
import { observable } from "mobx";
import { Bullpen } from "models/Bullpen";
import { User } from "models/UserModel";
import { TripolyCheck } from "./TripolyCheck";
import { SummarySpecie } from "./SummarySpecie";
import { SummaryCustomer } from "./SummaryCustomer";
import { SummaryShellStock } from "./SummaryShellStock";
import { JuiceSummarySpecie } from "./JuiceProductSummary";
import { JuiceBullpen } from "models/JuiceBullpen";

export class ProductSummary extends NewModel {
  static _store: ProductSummaryStore;

  @observable lastHourCageCount!: number;

  @observable currentShiftCageCount!: number;

  @observable general!: General;

  @observable species!: SummarySpecie[];

  @observable juiceSpecies!: JuiceSummarySpecie[];

  @observable customers!: SummaryCustomer[];

  @observable carryOver!: Bullpen[];

  @observable carryOverJuice!: JuiceBullpen[];

  @observable shellStocks!: SummaryShellStock[];
}

interface General {
  id: number;
  ice_usage_count: number;
  supervisor_count: number;
  production_count: number;
  maintenance_count: number;
  qc_count: number;
  total_count: number;
  comment: string;
  wash_sanitize_time: string[];
  tripoly_check: TripolyCheck[];
  sand_summary?: SandSummaryConfig[];
  approved_by: number;
  approved_at: Date;
  created_at: Date;
  updated_at: Date;
  user: User;
}

export interface SandSummaryConfig {
  specification_id: number;
  boat_id: number;
  description: string;
}
