import { action, computed, observable } from "mobx";
import NewStore from "./NewStore";
import { ModelList } from "models/ModelList";
import { ModelItem } from "models/ModelItem";
import ApiRoutes from "routes/ApiRoutes";
import { JuiceSpecification } from "models/JuiceSpecification";

export class JuiceSpecificationStore extends NewStore<JuiceSpecification> {
    private static _instance: JuiceSpecificationStore;

    constructor() {
        super();
        JuiceSpecification._store = this;
    }

    static getInstance(): JuiceSpecificationStore {
        if (!this._instance) {
            this._instance = new JuiceSpecificationStore();
        }
        return this._instance;
    }

    @observable specNumberList = new ModelList<JuiceSpecification>(JuiceSpecification);

    @observable specNumberItem = new ModelItem<JuiceSpecification>(JuiceSpecification);

    @computed get specNumbers() {
        return this.specNumberList.items;
    }

    @action
    async fetchSpecNumbers(params?: { [key: string]: any }) {
        return this.specNumberList.load(ApiRoutes.juiceSpecNumbers.list, params);
    }

    @action
    async createElement(body: { [key: string]: any }) {
        const response = await this.apiService.post(
            ApiRoutes.juiceSpecNumbers.list,
            body,
        );
        const specNumber = JuiceSpecification.fromJson(response.data) as JuiceSpecification;
        this.specNumberList.setItems([specNumber, ...this.specNumberList.items]);
        return specNumber;
    }

    @action
    async updateElement(specNumber: JuiceSpecification, body: { [key: string]: any }) {
        try {
            specNumber.setUpdating(true);
            const response = await this.apiService.put(
                ApiRoutes.juiceSpecNumbers.updateSpecHistoryData(specNumber.id),
                body,
            );

            specNumber.updateFromJson(response.data);

            specNumber.setUpdating(false);
            return specNumber;
        } catch (e) {
            specNumber.setUpdating(false);
            throw e;
        }
    }

    @action
    async deleteElement(specNumber: JuiceSpecification) {
        try {
            specNumber.setDeleting(true);
            const body = {
                is_archived: !specNumber.is_archived,
            };
            const response = await this.apiService.put(
                ApiRoutes.juiceSpecNumbers.show(specNumber.id),
                body,
            );
            specNumber.setDeleting(false);
            specNumber.updateFromJson(response.data);
            specNumber.delete();
        } catch (e) {
            specNumber.setDeleting(false);
            throw e;
        }
    }

    @action
    async fetchSpecNumberById(id: number) {
        return this.specNumberItem.load(ApiRoutes.juiceSpecNumbers.show(id));
    }
}
