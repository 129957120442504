export function getCompleteRoute(val: RouteComponents, data = {}) {
  let generatedRoute: string | RouteComponents = val;
  let lastComp = val;
  while (routeMap[lastComp]) {
    generatedRoute = `${routeMap[lastComp]}/${generatedRoute}`;
    lastComp = routeMap[lastComp];
  }
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key of Object.keys(data)) {
    generatedRoute = generatedRoute.replace(`:${key}`, data[key]);
  }

  return `/${generatedRoute}`;
  // TODO add feature for returning both absolute OR relative relatives
}

function getBreadCrumbData(val: RouteComponents, data?: any): any[] {
  const generatedCrumbs = [
    { title: routeNamesForBreadCrumbs[val], url: getCompleteRoute(val) },
  ];
  let lastComp = val;
  while (breadCrumbsMap[lastComp]) {
    generatedCrumbs.push({
      title: routeNamesForBreadCrumbs[breadCrumbsMap[lastComp]],
      url: getCompleteRoute(breadCrumbsMap[lastComp]),
    });
    lastComp = breadCrumbsMap[lastComp];
  }

  if (data) {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of generatedCrumbs) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(data)) {
        item.url = item.url.replace(`:${key}`, data[key]);
      }
    }
  }

  return generatedCrumbs;
}

function getPageTitle(val: RouteComponents): string {
  return routeNamesForBreadCrumbs[val];
}

export function getPageTitleMeta(
  val: RouteComponents,
  data?: any,
): [string, any[]] {
  return [getPageTitle(val), getBreadCrumbData(val, data).reverse()];
}

// enum for routeComponents
export enum RouteComponents {
  AUTH = "auth",
  LOGIN = "login",
  FORGOT_PASSWORD_PAGE = "forgot-password",
  RESET_PASSWORD_PAGE = "reset-password",
  HOME = "home",
  TODAYS_PRODUCTION = "production-today",
  GOALS = "goals",
  APPROVE_BULLPENS = "approve-bullpens",
  APPROVE_JUICE_BULLPENS = "approve-juice-bullpens",
  SHELL_STOCK = "shellstock",
  SHELL_STOCK_VIEW = "shellstock-view",
  SHELL_STOCK_ADD = "shellstock-add",
  SHELL_STOCK_DETAIL = "details/:id",
  SHELL_STOCK_EDIT = "edit/:id",
  DUMPING = "dumping",
  GRIND_INSPECTION = "qc-inspection",
  GRIND_INSPECTION_VIEW = "qc-inspection-view",
  GRIND_INSPECTION_NEW = "qc-inspection-new",
  GRIND_INSPECTION_ONGOING = "qc-inspection-ongoing",
  GRIND_INSPECTION_ADD_STEP_TWO = ":id/add",
  GRIND_INSPECTION_DETAILS = ":id/inspection-details",
  TUB_SHEETS = "tub-sheets",
  TUB_SHEETS_VIEW = "tub-sheets-view",
  TUB_SHEETS_ONGOING = "tub-sheets-ongoing",
  TUB_SHEETS_ADD = "add/:id",
  TUB_SHEETS_DETAILS = ":id/tubsheets-details",
  DISTRIBUTION = "distribution",
  DISTRIBUTION_VIEW = "distribution-view",
  DISTRIBUTION_ADD = "distribution-add",
  TRUCK_DETAILS = ":id/truck-details",
  MANAGEMENT = "management",
  MANAGE_TAGS = "manage-tags",
  MANAGE_BOAT = "manage-boat",
  MANAGE_CUSTOMER = "manage-customer",
  MANAGE_SPECIE = "manage-species",
  MANAGE_SPECIFICATION = "manage-specification",
  MANAGE_SHIFT = "manage-shift",
  MANAGE_HARVEST_LOCATION = "manage-harvest-location",
  MANAGE_BULLPEN_WEIGHTS = "manage-bullpen-weights",
  MANAGE_COOLERS = "manage-coolers",
  MANAGE_PACKING_SIZES = "manage-packing-sizes",
  MANAGE_BULLPEN_SIZES = "manage-bullpen-sizes",
  MANAGE_CUT_SIZES = "manage-cut-sizes",
  MANAGEMENT_HOME = "mgt-home",
  MANAGE_SPEC_HISTORY = ":id/spec-history",
  MANAGE_JUICE_SPEC_HISTORY = ":id/juice-spec-history",
  MANAGE_SPEC_DETAILS = ":specHist/view",
  MANAGE_JUICE_SPEC_DETAILS = ":juiceSpecHist/view",
  MANAGE_SPEC_DETAILS_EDIT = ":id/edit",
  MANAGE_JUICE_SPEC_DETAILS_EDIT = ":id/spec-edit",
  MANAGE_JUICE_SPECIFICATIONS = "manage-juice-specifications",
  MANAGE_TAG_OWNER = "manage-tag-owner",
  PRODUCTION_SUMMARY_REPORT = "product-summary-report",
  PROFILE = "profile",
  REPORT_GENERATION = "report-generation",
  ACCOUNT = "account",
  GENERAL = "general",
  USERS = "users",
  USERS_HOME = "user-home",
  USERS_ADD = "add",
  USERS_DETAIL = "user-detail/:id",
  PAGE_NOT_FOUND = "*",
  FORMS = "forms",
  FORMS_VIEW_ALL = "forms-view-all",
  FORMS_ADD = "forms-add",
  INVENTORY_MANAGEMENT = "inventory",
  YIELD_REPORT = "yield-report",
  FROZEN = "frozen",
  TAGS = "tags",
  GOALS_REPORT = "goals-report",
  SAND_REPORT = "sand-report",
  JUICE_INSPECTION = "juice-inspection",
  JUICE_INSPECTION_VIEW = "juice-inspection-view",
  JUICE_INSPECTION_ADD = "juice-inspection-add",
  JUICE_INSPECTION_ONGOING = "juice-inspection-ongoing",
  JUICE_INSPECTION_ADD_STEP_TWO = ":id/add-juice",
  JUICE_INSPECTION_DETAILS = ":id/juice-inspection-details",
  JUICE_BULLPENS = "juice-bullpens",
  JUICE_BULLPENS_VIEW = "juice-bullpens-view",
  JUICE_BULLPENS_ONGOING = "juice-bullpens-ongoing",
  JUICE_BULLPENS_ADD = "juice-bullpens-add/:id",
  JUICE_BULLPENS_DETAILS = ":id/juice-bullpens-details",
}

// defined child parent mapping
const routeMap: any = {
  [RouteComponents.LOGIN]: RouteComponents.AUTH,
  [RouteComponents.FORGOT_PASSWORD_PAGE]: RouteComponents.AUTH,
  [RouteComponents.RESET_PASSWORD_PAGE]: RouteComponents.AUTH,
  [RouteComponents.GOALS]: RouteComponents.HOME,
  [RouteComponents.APPROVE_BULLPENS]: RouteComponents.HOME,
  [RouteComponents.APPROVE_JUICE_BULLPENS]: RouteComponents.HOME,
  [RouteComponents.TODAYS_PRODUCTION]: RouteComponents.HOME,
  [RouteComponents.SHELL_STOCK]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.SHELL_STOCK_VIEW]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.SHELL_STOCK_ADD]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.SHELL_STOCK_DETAIL]: RouteComponents.SHELL_STOCK,
  [RouteComponents.SHELL_STOCK_EDIT]: RouteComponents.SHELL_STOCK,
  [RouteComponents.DUMPING]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.GRIND_INSPECTION]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.GRIND_INSPECTION_VIEW]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.GRIND_INSPECTION_NEW]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.GRIND_INSPECTION_ONGOING]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.GRIND_INSPECTION_DETAILS]: RouteComponents.GRIND_INSPECTION,
  [RouteComponents.GRIND_INSPECTION_ADD_STEP_TWO]:
    RouteComponents.GRIND_INSPECTION,
  [RouteComponents.TUB_SHEETS]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.TUB_SHEETS_VIEW]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.TUB_SHEETS_ONGOING]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.TUB_SHEETS_ADD]: RouteComponents.TUB_SHEETS,
  [RouteComponents.TUB_SHEETS_DETAILS]: RouteComponents.TUB_SHEETS,
  [RouteComponents.DISTRIBUTION]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.DISTRIBUTION_VIEW]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.DISTRIBUTION_ADD]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.TRUCK_DETAILS]: RouteComponents.DISTRIBUTION,
  [RouteComponents.MANAGE_TAGS]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_BOAT]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_CUSTOMER]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_SPECIE]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_SPECIFICATION]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_JUICE_SPECIFICATIONS]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_SHIFT]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_HARVEST_LOCATION]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_BULLPEN_WEIGHTS]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_COOLERS]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_PACKING_SIZES]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_BULLPEN_SIZES]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_CUT_SIZES]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGEMENT_HOME]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_SPEC_HISTORY]: RouteComponents.MANAGE_SPECIFICATION,
  [RouteComponents.MANAGE_JUICE_SPEC_HISTORY]: RouteComponents.MANAGE_JUICE_SPECIFICATIONS,
  [RouteComponents.MANAGE_SPEC_DETAILS]: RouteComponents.MANAGE_SPEC_HISTORY,
  [RouteComponents.MANAGE_JUICE_SPEC_DETAILS]: RouteComponents.MANAGE_JUICE_SPEC_HISTORY,
  [RouteComponents.MANAGE_SPEC_DETAILS_EDIT]:
    RouteComponents.MANAGE_SPECIFICATION,
  [RouteComponents.MANAGE_JUICE_SPEC_DETAILS_EDIT]: RouteComponents.MANAGE_JUICE_SPECIFICATIONS,
  [RouteComponents.MANAGE_TAG_OWNER]: RouteComponents.MANAGEMENT,
  [RouteComponents.USERS_HOME]: RouteComponents.USERS,
  [RouteComponents.USERS_ADD]: RouteComponents.USERS,
  [RouteComponents.USERS_DETAIL]: RouteComponents.USERS,
  [RouteComponents.ACCOUNT]: RouteComponents.PROFILE,
  [RouteComponents.GENERAL]: RouteComponents.PROFILE,
  [RouteComponents.YIELD_REPORT]: RouteComponents.INVENTORY_MANAGEMENT,
  [RouteComponents.FROZEN]: RouteComponents.INVENTORY_MANAGEMENT,
  [RouteComponents.TAGS]: RouteComponents.INVENTORY_MANAGEMENT,
  [RouteComponents.GOALS_REPORT]: RouteComponents.INVENTORY_MANAGEMENT,
  [RouteComponents.JUICE_INSPECTION]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.JUICE_INSPECTION_VIEW]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.JUICE_INSPECTION_ADD]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.JUICE_INSPECTION_ONGOING]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.JUICE_INSPECTION_ADD_STEP_TWO]: RouteComponents.JUICE_INSPECTION,
  [RouteComponents.JUICE_INSPECTION_DETAILS]: RouteComponents.JUICE_INSPECTION,
  [RouteComponents.JUICE_BULLPENS]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.JUICE_BULLPENS_VIEW]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.JUICE_BULLPENS_ONGOING]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.JUICE_BULLPENS_DETAILS]: RouteComponents.JUICE_BULLPENS,
  [RouteComponents.JUICE_BULLPENS_ADD]: RouteComponents.JUICE_BULLPENS,
  [RouteComponents.SAND_REPORT]: RouteComponents.INVENTORY_MANAGEMENT,
};

const breadCrumbsMap: any = {
  [RouteComponents.GOALS]: RouteComponents.HOME,
  [RouteComponents.SHELL_STOCK]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.SHELL_STOCK_DETAIL]: RouteComponents.SHELL_STOCK,
  [RouteComponents.SHELL_STOCK_EDIT]: RouteComponents.SHELL_STOCK,
  [RouteComponents.DUMPING]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.GRIND_INSPECTION]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.GRIND_INSPECTION_DETAILS]: RouteComponents.GRIND_INSPECTION,
  [RouteComponents.GRIND_INSPECTION_ADD_STEP_TWO]:
    RouteComponents.GRIND_INSPECTION,
  [RouteComponents.TUB_SHEETS]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.TUB_SHEETS_ADD]: RouteComponents.TUB_SHEETS,
  [RouteComponents.TUB_SHEETS_DETAILS]: RouteComponents.TUB_SHEETS,
  [RouteComponents.DISTRIBUTION]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.TRUCK_DETAILS]: RouteComponents.DISTRIBUTION,
  [RouteComponents.MANAGE_TAGS]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_BOAT]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_CUSTOMER]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_SPECIE]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_SPECIFICATION]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_JUICE_SPECIFICATIONS]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_SHIFT]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_HARVEST_LOCATION]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_BULLPEN_WEIGHTS]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_COOLERS]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_PACKING_SIZES]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_BULLPEN_SIZES]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_CUT_SIZES]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGEMENT_HOME]: RouteComponents.MANAGEMENT,
  [RouteComponents.MANAGE_SPEC_HISTORY]: RouteComponents.MANAGE_SPECIFICATION,
  [RouteComponents.MANAGE_JUICE_SPEC_HISTORY]: RouteComponents.MANAGE_JUICE_SPECIFICATIONS,
  [RouteComponents.MANAGE_SPEC_DETAILS]: RouteComponents.MANAGE_SPEC_HISTORY,
  [RouteComponents.MANAGE_JUICE_SPEC_DETAILS]: RouteComponents.MANAGE_JUICE_SPEC_HISTORY,
  [RouteComponents.MANAGE_SPEC_DETAILS_EDIT]:
    RouteComponents.MANAGE_SPECIFICATION,
  [RouteComponents.MANAGE_JUICE_SPEC_DETAILS_EDIT]: RouteComponents.MANAGE_JUICE_SPECIFICATIONS,
  [RouteComponents.MANAGE_TAG_OWNER]: RouteComponents.MANAGEMENT,
  [RouteComponents.USERS_HOME]: RouteComponents.USERS,
  [RouteComponents.USERS_ADD]: RouteComponents.USERS,
  [RouteComponents.USERS_DETAIL]: RouteComponents.USERS,
  [RouteComponents.ACCOUNT]: RouteComponents.PROFILE,
  [RouteComponents.GENERAL]: RouteComponents.PROFILE,
  [RouteComponents.YIELD_REPORT]: RouteComponents.INVENTORY_MANAGEMENT,
  [RouteComponents.FROZEN]: RouteComponents.INVENTORY_MANAGEMENT,
  [RouteComponents.TAGS]: RouteComponents.INVENTORY_MANAGEMENT,
  [RouteComponents.GOALS_REPORT]: RouteComponents.INVENTORY_MANAGEMENT,
  [RouteComponents.JUICE_INSPECTION]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.JUICE_INSPECTION_ADD_STEP_TWO]: RouteComponents.JUICE_INSPECTION,
  [RouteComponents.JUICE_INSPECTION_DETAILS]: RouteComponents.JUICE_INSPECTION,
  [RouteComponents.JUICE_BULLPENS]: RouteComponents.TODAYS_PRODUCTION,
  [RouteComponents.JUICE_BULLPENS_DETAILS]: RouteComponents.JUICE_BULLPENS,
  [RouteComponents.JUICE_BULLPENS_ADD]: RouteComponents.JUICE_BULLPENS,
  [RouteComponents.SAND_REPORT]: RouteComponents.INVENTORY_MANAGEMENT,
};

const routeNamesForBreadCrumbs = {
  [RouteComponents.HOME]: "Home",
  [RouteComponents.TODAYS_PRODUCTION]: "Today's Production",
  [RouteComponents.LOGIN]: "Login",
  [RouteComponents.GOALS]: "Goals",
  [RouteComponents.APPROVE_BULLPENS]: "Approve Bullpens",
  [RouteComponents.APPROVE_JUICE_BULLPENS]: "Approve Juice Bullpens",
  [RouteComponents.SHELL_STOCK]: "Shellstock",
  [RouteComponents.DUMPING]: "Dumping",
  [RouteComponents.GRIND_INSPECTION]: "QC Inspection",
  [RouteComponents.GRIND_INSPECTION_DETAILS]: "Inspection Details",
  [RouteComponents.GRIND_INSPECTION_ADD_STEP_TWO]: "Inspection Add",
  [RouteComponents.TUB_SHEETS]: "Bullpens",
  [RouteComponents.TUB_SHEETS_ADD]: "Bullpens Add",
  [RouteComponents.DISTRIBUTION]: "Distribution",
  [RouteComponents.MANAGEMENT]: "Management",
  [RouteComponents.MANAGE_TAGS]: "Manage Tags",
  [RouteComponents.MANAGE_BOAT]: "Manage Boat",
  [RouteComponents.MANAGE_CUSTOMER]: "Manage Customer",
  [RouteComponents.MANAGE_SPECIE]: "Manage Species",
  [RouteComponents.MANAGE_SPECIFICATION]: "Manage Specification",
  [RouteComponents.MANAGE_JUICE_SPECIFICATIONS]: "Manage Juice Specifications",
  [RouteComponents.MANAGE_SHIFT]: "Manage Shift",
  [RouteComponents.MANAGE_HARVEST_LOCATION]: "Manage Harvest Location",
  [RouteComponents.MANAGE_BULLPEN_WEIGHTS]: "Manage Bullpen Weights",
  [RouteComponents.MANAGE_COOLERS]: "Manage Coolers",
  [RouteComponents.MANAGE_PACKING_SIZES]: "Manage Packing Sizes",
  [RouteComponents.MANAGE_BULLPEN_SIZES]: "Manage Bullpen Sizes",
  [RouteComponents.MANAGE_CUT_SIZES]: "Manage Cut Sizes",
  [RouteComponents.MANAGEMENT_HOME]: "Management Home",
  [RouteComponents.MANAGE_SPEC_DETAILS]: "Specification Details",
  [RouteComponents.MANAGE_JUICE_SPEC_DETAILS]: "Juice Specification Details",
  [RouteComponents.MANAGE_SPEC_DETAILS_EDIT]: "Specification Edit",
  [RouteComponents.MANAGE_JUICE_SPEC_DETAILS_EDIT]: "Juice Specification Edit",
  [RouteComponents.MANAGE_SPEC_HISTORY]: "Specification History",
  [RouteComponents.MANAGE_JUICE_SPEC_HISTORY]: "Juice Specification History",
  [RouteComponents.MANAGE_TAG_OWNER]: "Manage Tag Owner",
  [RouteComponents.TRUCK_DETAILS]: "Truck Details",
  [RouteComponents.SHELL_STOCK_DETAIL]: "Shellstock Load",
  [RouteComponents.SHELL_STOCK_EDIT]: "Shellstock Edit",
  [RouteComponents.TUB_SHEETS_DETAILS]: "Bullpen Details",
  [RouteComponents.PRODUCTION_SUMMARY_REPORT]: "Report",
  [RouteComponents.PROFILE]: "Profile",
  [RouteComponents.REPORT_GENERATION]: "Report Generation",
  [RouteComponents.ACCOUNT]: "Account",
  [RouteComponents.GENERAL]: "General",
  [RouteComponents.USERS]: "Users",
  [RouteComponents.USERS_HOME]: "User Home",
  [RouteComponents.USERS_ADD]: "Add",
  [RouteComponents.USERS_DETAIL]: "Users",
  [RouteComponents.PAGE_NOT_FOUND]: "",
  [RouteComponents.FORMS]: "Forms",
  [RouteComponents.FORMS_VIEW_ALL]: "Forms View All",
  [RouteComponents.FORMS_ADD]: "Forms Add",
  [RouteComponents.INVENTORY_MANAGEMENT]: "Inventory",
  [RouteComponents.YIELD_REPORT]: "Yield Report",
  [RouteComponents.FROZEN]: "Frozen Inventory",
  [RouteComponents.TAGS]: "Tags Reports",
  [RouteComponents.GOALS_REPORT]: "Goals Report",
  [RouteComponents.SAND_REPORT]: "Sand Report",
  [RouteComponents.JUICE_INSPECTION]: "Juice Inspection",
  [RouteComponents.JUICE_INSPECTION_ADD_STEP_TWO]: "Juice Inspection Add",
  [RouteComponents.JUICE_INSPECTION_DETAILS]: "Juice Inspection Details",
  [RouteComponents.JUICE_BULLPENS]: "Juice Bullpens",
  [RouteComponents.JUICE_BULLPENS_DETAILS]: "Juice Bullpen Details",
  [RouteComponents.JUICE_BULLPENS_ADD]: "Juice Bullpen Add",
};
