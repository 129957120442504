import NewStore from "./NewStore";
import { Distribution } from "models/distribution/Distribution";
import { action, computed, observable } from "mobx";
import ApiRoutes from "routes/ApiRoutes";
import { ModelItem } from "models/ModelItem";
import { GrindInspectionAddStatus } from "enums/GrindInspectionAddStatus";
import { Bullpen } from "models/Bullpen";
import { Specification } from "models/Specification";
import { PaginatedModelList } from "models/PaginatedModelList";
import { toast } from "react-toastify";

export class DistributionStore extends NewStore<Distribution> {
  private static _instance: DistributionStore;

  @observable distributionList = new PaginatedModelList<Distribution>(
    Distribution,
  );

  @observable distributionItem = new ModelItem<Distribution>(Distribution);

  @observable todaysProductionList: Bullpen[] = [];

  @observable bullpensFromCoolers: Bullpen[] = [];

  @observable isFetchingTodaysProduction: boolean = false;

  @observable isFetchingBullpensFromCooler: boolean = false;

  @observable customer: Specification | undefined;

  @observable bullpensFromClientCoolers: Bullpen[] = [];

  @observable isFetchingClientsBullpens: boolean = false;

  @observable detailPageId: number = 0;

  constructor() {
    super();
    Distribution._store = this;
  }

  static getInstance(): DistributionStore {
    if (!this._instance) {
      this._instance = new DistributionStore();
    }
    return this._instance;
  }

  @computed get distributions() {
    return this.distributionList.items;
  }

  @action
  async fetchDistributions(params?: { [key: string]: any }) {
    return this.distributionList.load(ApiRoutes.distribution.list, params);
  }

  @action
  async fetchDistributionById(id: number) {
    return this.distributionItem.load(ApiRoutes.distribution.show(id));
  }

  @action
  async fetchSearchedQuery(query?: { [key: string]: any }) {
    this.searchFilterParam = { ...this.searchFilterParam, ...query };
    await this.fetchDistributions(this.searchFilterParam);
    if (this.searchFilterParam.page) {
      delete this.searchFilterParam.page;
    }
  }

  @action
  async sendForReworkBullpens(bullpenIds: number[], id: number) {
    await this.apiService.post(ApiRoutes.distribution.rework, {
      bullpen_ids: bullpenIds,
    });
    const distribute = this.distributionList.items.filter(
      element => element.id === id,
    );
    if (distribute.length !== 0) {
      distribute[0].bullpenDistributions.forEach(bull => {
        bullpenIds.forEach(bullId => {
          if (bull.bullpen_id === bullId) {
            bull.bullpen.status = GrindInspectionAddStatus.REWORKED;
          }
        });
      });
    }
  }

  @action
  async fetchTodaysProductionList() {
    try {
      this.isFetchingTodaysProduction = true;
      const response = await this.apiService.get(
        ApiRoutes.distribution.todaysBullpens,
      );
      this.todaysProductionList = [];
      this.todaysProductionList.push(...response.data);
    } finally {
      this.isFetchingTodaysProduction = false;
    }
  }

  @action
  async fetchBullpensFromCooler(coolerId: number) {
    try {
      this.isFetchingBullpensFromCooler = true;
      const response = await this.apiService.get(
        ApiRoutes.distribution.listBullpenFromCooler(coolerId),
      );
      this.bullpensFromCoolers = [];
      this.bullpensFromCoolers.push(...response.data);
    } finally {
      this.isFetchingBullpensFromCooler = false;
    }
  }

  @action
  async sendBullpensForRework(productionList: number[], coolerList: number[]) {
    const complete = [...productionList, ...coolerList];
    await this.apiService.post(ApiRoutes.distribution.rework, {
      bullpen_ids: complete,
    });
    this.removeProductionBullpens(productionList);
    this.removeCoolerBullpens(coolerList);
  }

  @action
  async moveToCooler(
    productionList: number[],
    coolerList: number[],
    coolerId: number,
  ) {
    const complete = [...productionList, ...coolerList];
    const body = {
      bullpen_ids: complete,
      cooler_id: coolerId,
    };
    await this.apiService.post(ApiRoutes.tubSheet.moveToCooler, body);
  }

  @action
  async getCustomerAndProduct(
    productionList?: number[],
    coolerList?: number[],
    coolerId?: number,
    sendToFrozen?: boolean,
  ) {
    let response;
    if (productionList) {
      const complete = [...productionList, ...coolerList];
      if (sendToFrozen) {
        response = await this.apiService.post(
          ApiRoutes.distribution.getCustomer,
          { bullpen_ids: complete, send_to_frozen: true },
        );
      } else {
        response = await this.apiService.post(
          ApiRoutes.distribution.getCustomer,
          { bullpen_ids: complete },
        );
      }
    } else {
      response = await this.apiService.post(
        ApiRoutes.distribution.getCustomer,
        { cooler_id: coolerId },
      );
    }
    this.customer = Specification.fromJson(response.data) as Specification;
  }

  @action
  async createDistribution(
    body: { [key: string]: any },
    productionList?: number[],
    coolerList?: number[],
  ) {
    const response = await this.apiService.post(
      ApiRoutes.distribution.list,
      body,
    );
    if (productionList) {
      this.removeProductionBullpens(productionList);
      this.removeCoolerBullpens(coolerList);
    }
    const distribution = Distribution.fromJson(response.data) as Distribution;
    this.distributionList.setItems([
      distribution,
      ...this.distributionList.items,
    ]);
    return distribution;
  }

  @action
  async fetchClientCoolerBullpens(coolerId: number) {
    try {
      this.isFetchingClientsBullpens = true;
      const response = await this.apiService.get(
        ApiRoutes.distribution.listBullpenFromCooler(coolerId),
      );
      this.bullpensFromClientCoolers = [];
      this.bullpensFromClientCoolers.push(...response.data);
    } finally {
      this.isFetchingClientsBullpens = false;
    }
  }

  @action
  removeProductionBullpens(list: number[]) {
    list.forEach(id => {
      const index = this.todaysProductionList.findIndex(
        element => element.id === id,
      );
      if (index !== undefined) {
        this.todaysProductionList.splice(index, 1);
      }
    });
  }

  @action
  removeCoolerBullpens(list: number[]) {
    list.forEach(id => {
      const index = this.bullpensFromCoolers.findIndex(
        element => element.id === id,
      );
      if (index !== undefined) {
        this.bullpensFromCoolers.splice(index, 1);
      }
    });
  }

  @action
  setDetailPageId(id: number) {
    this.detailPageId = id;
  }

  @action
  getDetailPageId() {
    return this.detailPageId;
  }
}
