import React, { Suspense } from "react";
import { Provider } from "mobx-react";

import { createStores } from "../stores/createStore";
import { StylesProvider } from "@material-ui/styles";
import { StyledToastContainer } from "../components/globals";
import { Zoom } from "react-toastify";
import SplashScreen from "../components/splash-screen/SplashScreen";
import FullScreenLoader from "../components/shared/full-screen-loader/FullScreenLoader";
import { appRoutes } from "./app.route";
import { BrowserRouter as Router } from "react-router-dom";

const routes = (
  <Provider {...createStores()}>
    <StylesProvider injectFirst>
      <StyledToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        closeButton={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        transition={Zoom}
      />
      <Router>
        <Suspense fallback={<FullScreenLoader />}>
          <SplashScreen>{appRoutes}</SplashScreen>
        </Suspense>
      </Router>
    </StylesProvider>
  </Provider>
);

export default routes;
