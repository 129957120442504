import { action, computed, observable } from "mobx";
import { User } from "models/UserModel";
import { authService } from "services/AuthService";
import { apiService, Dictionary } from "services/ApiService";
import NewStore from "./NewStore";
import { ModelItem } from "models/ModelItem";
import ApiRoutes from "routes/ApiRoutes";
import { PaginatedModelList } from "models/PaginatedModelList";

export class UserStore extends NewStore<User> {
  private static _instance: UserStore;

  @observable loadingSignedUrl: boolean = false;

  @observable isImageUploading: boolean = false;

  @observable loggedInUserItem = new ModelItem<User>(User);

  @observable userItem = new ModelItem<User>(User);

  @observable userList = new PaginatedModelList<User>(User);

  constructor() {
    super();
    User._store = this;
    this.searchFilterParam.limit = 30;
  }

  static getInstance(): UserStore {
    if (!this._instance) {
      this._instance = new UserStore();
    }

    return this._instance;
  }

  @computed get loggedInUser() {
    return this.loggedInUserItem.item;
  }

  @computed get isLoggedIn() {
    return !!this.loggedInUser;
  }

  @computed get users() {
    return this.userList.items.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );
  }

  @computed get invitedUsers() {
    return this.users.filter(e => e.status === "invited");
  }

  @computed get activeUsers() {
    return this.users.filter(e => e.status === "active");
  }

  @computed get inactiveUsers() {
    return this.users.filter(e => e.status === "inactive");
  }

  async login(username: string, password: string) {
    const response = await authService.login(username, password);
    const user = User.fromJson(response) as User;
    localStorage.setItem("user_id", user.id.toString());
    this.loggedInUserItem.setItem(user);
  }

  @action
  async getMe() {
    try {
      this.loggedInUserItem.setLoading(true);
      const response = await this.apiService.get(ApiRoutes.auth.me);
      const user = User.fromJson(response.data) as User;
      this.loggedInUserItem.setItem(user);
      this.loggedInUserItem.setLoading(false);
    } catch (e) {
      localStorage.clear();
      this.loggedInUserItem.setLoading(false);
      throw e;
    }
  }

  @action
  async changePassword(oldPassword: string, newPassword: string) {
    const body = {
      old_password: oldPassword,
      new_password: newPassword,
    };
    return this.apiService.post(ApiRoutes.auth.changePassword, body);
  }

  @action
  async forgotPassword(email: string) {
    return this.apiService.post(ApiRoutes.auth.forgotPassword, { email });
  }

  @action
  async resetPassword(body: { [key: string]: any }) {
    const response = await authService.resetPassword(body);
    const user = User.fromJson(response) as User;
    this.loggedInUserItem.setItem(user);
  }

  @action
  async updateMe(body: { [key: string]: any }) {
    const response = await this.apiService.put(ApiRoutes.auth.me, body);
    const user = User.fromJson(response.data) as User;
    this.loggedInUserItem.setItem(user);
  }

  @action
  async getSignedUrl(file: File): Promise<string> {
    try {
      this.loadingSignedUrl = true;
      const body = {
        file_name: file.name,
        directory: "user-profile",
        content_type: file.type,
      };
      const response = await authService.getPreSignedUrl(body);
      this.loadingSignedUrl = false;
      return response;
    } catch (e) {
      this.loadingSignedUrl = false;
      throw e;
    }
  }

  @action
  async fetchUsers(queryParams?: Dictionary<any>) {
    return this.userList.load(ApiRoutes.users.list, queryParams);
  }

  @action
  async fetchUserFromId(id: number) {
    return this.userItem.load(ApiRoutes.users.show(id));
  }

  @action
  async updateUser(user: User, body: { [key: string]: any }) {
    const response = await this.apiService.put(
      ApiRoutes.users.show(user.id),
      body,
    );
    user.updateFromJson(response.data);
    const index = this.userList.items.findIndex(res => res.id === user.id);
    if (
      index !== -1 &&
      this.searchFilterParam.designation_ids &&
      user.designation_id !== this.searchFilterParam.designation_ids[0]
    ) {
      const { items } = this.userList;
      items.splice(index, 1);
      this.userList.setItems(items);
    }
    return user;
  }

  @action
  async createUser(body: { [key: string]: any }) {
    const response = await this.apiService.post(ApiRoutes.users.list, body);
    const user = User.fromJson(response.data) as User;
    this.userList.appendItem(user);
    return user;
  }

  @action
  async logout() {
    localStorage.clear();
  }

  async upload(file): Promise<string> {
    try {
      this.isImageUploading = true;
      const url = await this.getSignedUrl(file!);
      await apiService.put(url, false, file, false);
      this.isImageUploading = false;
      return url.split("?")[0];
    } catch (e) {
      this.isImageUploading = false;
      throw e;
    }
  }

  @action
  async fetchSearchedQuery(query?: { [key: string]: any }) {
    this.searchFilterParam = { ...this.searchFilterParam, ...query };
    await this.fetchUsers(this.searchFilterParam);
    if (this.searchFilterParam.page) {
      delete this.searchFilterParam.page;
    }
  }

  @action
  async copyInvitationLink(id: number) {
    const response = await this.apiService.get(ApiRoutes.users.copyInvite(id));
    return response.data;
  }

  @action
  async resendInvitation(id: number) {
    await this.apiService.get(ApiRoutes.users.resendInvite(id));
  }

  @action
  async cancelInvitation(user: User) {
    await this.apiService.get(ApiRoutes.users.cancelInvite(user.id));
    user.status = "cancelled_invite";
  }

  @action
  async reActivateInvitation(user: User) {
    await this.apiService.get(ApiRoutes.users.reActivateInvite(user.id));
    user.status = "invited";
  }
}
