import {
  STORE_APPROVE_BULLPENS,
  STORE_BOAT,
  STORE_BULLPEN_SIZE,
  STORE_BULLPEN_WEIGHT,
  STORE_COOLER,
  STORE_CUSTOMER,
  STORE_CUTSIZE,
  STORE_DESIGNATION,
  STORE_DISTRIBUTION,
  STORE_DOWNTIME,
  STORE_DUMPING,
  STORE_GOALS,
  STORE_GRIND_INSPECTION,
  STORE_HARVEST_LOCATION,
  STORE_PACKING_SIZE,
  STORE_PERMISSION,
  STORE_PRODUCT_SUMMARY,
  STORE_SHELL_STOCK,
  STORE_SHIFT,
  STORE_SPECIFICATION,
  STORE_SPECIE,
  STORE_TAG,
  STORE_USER,
  STORE_TUBSHEET,
  STORE_SOPFORM,
  STORE_JUICE_SPECIFICATION,
  STORE_JUICE_INSPECTION,
  STORE_APPROVE_JUICE_BULLPENS,
  STORE_JUICE_BULLPEN,
  STORE_TAG_OWNER,
} from "constants/stores";
import { ShiftStore } from "./ShiftStore";
import { BoatStore } from "./BoatStore";
import { BullpenSizeStore } from "./BullpenSizeStore";
import { BullpenWeightStore } from "./BullpenWeightStore";
import { CoolerStore } from "./CoolerStore";
import { CustomerStore } from "./CustomerStore";
import { CutSizeStore } from "./CutSizeStore";
import { DesignationStore } from "./DesignationStore";
import { DumpingStore } from "./DumpingStore";
import { GrindInspectionStore } from "./GrindInspectionStore";
import { HarvestLocationStore } from "./HarvestLocationStore";
import { PackingSizeStore } from "./PackingSizeStore";
import { PermissionStore } from "./PermissionStore";
import { ShellStockStore } from "./ShellStockStore";
import { SpecieStore } from "./SpecieStore";
import { SpecificationStore } from "./SpecificationStore";
import { TagStore } from "./TagStore";
import { UserStore } from "./UserStore";
import { TubSheetStore } from "./TubSheetStore";
import { ApproveBullpensStore } from "./ApproveBullpensStore";
import { ProductSummaryStore } from "./ProductSummaryStore";
import { DistributionStore } from "./DistributionStore";
import { DownTimeStore } from "./DownTimeStore";
import { GoalsStore } from "./GoalsStore";
import { SOPFormStore } from "./SOPFormStore";
import { JuiceSpecificationStore } from "./JuiceSpecificationStore";
import { JuiceInspectionStore } from "./JuiceInspectionStore";
import { ApproveJuiceBullpensStore } from "./ApproveJuiceBullpenStore";
import { JuiceBullpenStore } from "./JuiceBullpenStore";
import { TagOwnerStore } from "./TagOwnerStore";

let stores = null;

export function createStores() {
  if (!stores) {
    stores = createStoresInstance();
  }
  console.log('stores', stores)
  return stores;
}

// Don't use this function outside tests.
export function createStoresInstance() {
  return {
    [STORE_APPROVE_BULLPENS]: new ApproveBullpensStore(),
    [STORE_BOAT]: new BoatStore(),
    [STORE_BULLPEN_SIZE]: new BullpenSizeStore(),
    [STORE_BULLPEN_WEIGHT]: new BullpenWeightStore(),
    [STORE_COOLER]: new CoolerStore(),
    [STORE_CUSTOMER]: new CustomerStore(),
    [STORE_CUTSIZE]: new CutSizeStore(),
    [STORE_DESIGNATION]: new DesignationStore(),
    [STORE_DISTRIBUTION]: new DistributionStore(),
    [STORE_DUMPING]: new DumpingStore(),
    [STORE_GOALS]: new GoalsStore(),
    [STORE_GRIND_INSPECTION]: new GrindInspectionStore(),
    [STORE_JUICE_INSPECTION]: new JuiceInspectionStore(),
    [STORE_HARVEST_LOCATION]: new HarvestLocationStore(),
    [STORE_PACKING_SIZE]: new PackingSizeStore(),
    [STORE_PERMISSION]: new PermissionStore(),
    [STORE_PRODUCT_SUMMARY]: new ProductSummaryStore(),
    [STORE_SHELL_STOCK]: new ShellStockStore(),
    [STORE_SHIFT]: new ShiftStore(),
    [STORE_SPECIE]: new SpecieStore(),
    [STORE_SPECIFICATION]: new SpecificationStore(),
    [STORE_JUICE_SPECIFICATION]: new JuiceSpecificationStore(),
    [STORE_TAG]: new TagStore(),
    [STORE_TUBSHEET]: new TubSheetStore(),
    [STORE_JUICE_BULLPEN]: new JuiceBullpenStore(),
    [STORE_USER]: new UserStore(),
    [STORE_DOWNTIME]: new DownTimeStore(),
    [STORE_SOPFORM]: new SOPFormStore(),
    [STORE_APPROVE_JUICE_BULLPENS]: new ApproveJuiceBullpensStore(),
    [STORE_TAG_OWNER]: new TagOwnerStore(),
  };
}
