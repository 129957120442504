import { action, computed, observable } from "mobx";
import NewStore from "./NewStore";
import { ModelList } from "models/ModelList";
import { Specie } from "models/Specie";
import { ModelItem } from "models/ModelItem";
import ApiRoutes from "routes/ApiRoutes";

export class SpecieStore extends NewStore<Specie> {
  private static _instance: SpecieStore;

  constructor() {
    super();
    Specie._store = this;
  }

  static getInstance(): SpecieStore {
    if (!this._instance) {
      this._instance = new SpecieStore();
    }
    return this._instance;
  }

  @observable specieList = new ModelList<Specie>(Specie);

  @observable specieItem = new ModelItem<Specie>(Specie);

  @computed get species() {
    return this.specieList.items;
  }

  @computed get activeSpecies() {
    return this.species.filter(specie => !specie.is_archived);
  }

  @computed get inActiveSpecies() {
    return this.species.filter(specie => specie.is_archived);
  }

  @action
  async fetchSpecies(params?: { [key: string]: any }) {
    return this.specieList.load(ApiRoutes.species.list, params);
  }

  @action
  async createElement(name) {
    const response = await this.apiService.post(ApiRoutes.species.list, {
      name,
    });
    const specie = Specie.fromJson(response.data) as Specie;
    this.specieList.appendItem(specie);
    return specie;
  }

  @action
  async updateElement(specie: Specie, body: { [key: string]: any }) {
    try {
      specie.setUpdating(true);
      const response = await this.apiService.put(
        ApiRoutes.species.show(specie.id),
        body,
      );
      specie.setUpdating(false);
      specie.updateFromJson(response.data);
      return specie;
    } catch (e) {
      specie.setUpdating(false);
      throw e;
    }
  }

  @action
  async deleteElement(specie: Specie) {
    try {
      specie.setDeleting(true);
      await this.apiService.delete(ApiRoutes.species.show(specie.id));
      specie.setDeleting(false);
      specie.delete();
    } catch (e) {
      specie.setDeleting(false);
      throw e;
    }
  }
}
