import { computed, observable } from "mobx";
import { TagStore } from "stores/TagStore";
import NewModel from "./NewModel";

export class Tag extends NewModel {
  static _store: TagStore;

  @observable id!: number;

  @observable name?: string;

  @observable starting_tag_no!: number;

  @observable ending_tag_no!: number;

  @observable used_count!: number;

  @observable is_archived!: boolean;

  @observable allocation_number?: string;

  @observable is_automated?: boolean;

  @observable created_at!: Date;

  @observable updated_at!: Date;

  @computed get getCages() {
    return this.ending_tag_no - this.starting_tag_no + 1;
  }

  @computed get getBushels() {
    return this.getCages * 32;
  }

  @computed get getAvailable(){
    return this.getCages - this.used_count;
  }
}
