import NewStore from "./NewStore";
import { action, computed, observable } from "mobx";
import { ModelList } from "models/ModelList";
import { WaitingForApprovalBullpen } from "models/WaitingForApprovalBullpen";
import ApiRoutes from "routes/ApiRoutes";
import moment from "moment";

export class ApproveBullpensStore extends NewStore<WaitingForApprovalBullpen> {
  private static _instance: ApproveBullpensStore;

  @observable bullpensList = new ModelList<WaitingForApprovalBullpen>(
    WaitingForApprovalBullpen,
  );

  constructor() {
    super();
    WaitingForApprovalBullpen._store = this;
  }

  @computed get bullpens() {
    return this.bullpensList.items;
  }

  static getInstance(): ApproveBullpensStore {
    if (!this._instance) {
      this._instance = new ApproveBullpensStore();
    }
    return this._instance;
  }

  @action
  async fetchWaitingForApprovalBullpens(
    params?: { [key: string]: any },
    config?: { [key: string]: any },
  ) {
    return this.bullpensList.load(
      ApiRoutes.approveBullpens.fetch,
      params,
      config,
    );
  }

  @action
  async approveBullpen(obj: WaitingForApprovalBullpen) {
    try {
      obj.setDeleting(true);
      await this.apiService.post(ApiRoutes.approveBullpens.approve(obj.id));
      obj.setDeleting(false);
      obj.delete();
    } catch (e) {
      obj.setDeleting(false);
      throw e;
    }
  }

  @action
  async rejectBullpen(obj: WaitingForApprovalBullpen) {
    try {
      obj.setDeleting(true);
      await this.apiService.post(ApiRoutes.approveBullpens.reject(obj.id));
      obj.setDeleting(false);
      obj.delete();
    } catch (e) {
      obj.setDeleting(false);
      throw e;
    }
  }

  @action
  async approveBullpensPolling() {
    let createdAt;
    const bullpens = this.bullpensList.items;

    if (bullpens.length > 0) {
      createdAt = bullpens[0].created_at;
      bullpens.forEach(bullpen => {
        if (bullpen.created_at > createdAt) {
          createdAt = bullpen.created_at;
        }
      });
    } else {
      createdAt = moment().subtract(1, "minutes").toDate();
    }
    const response = await this.apiService.get(
      ApiRoutes.approveBullpens.polling,
      {
        after: createdAt,
      },
    );
    const list = response.data.map(
      data =>
        WaitingForApprovalBullpen.fromJson(data) as WaitingForApprovalBullpen,
    );
    this.bullpensList.appendItems(list);
  }
}
