import { observable } from "mobx";
import NewModel from "./NewModel";

export class ManageElement extends NewModel {
  @observable id!: number;

  @observable name!: string;

  @observable is_archived!: boolean;

  @observable created_at!: Date;

  @observable updated_at!: Date;
}
