import { observable } from "mobx";
import { Specie } from "./Specie";
import { Customer } from "./Customer";
import NewModel from "./NewModel";
import { JuiceSpecificationStore } from "stores/JuiceSpecificationStore";
import { JuiceSpecificationHistory } from "./JuiceSpecificationHistory";

export class JuiceSpecification extends NewModel {
  static _store: JuiceSpecificationStore;

  @observable id!: number;

  @observable specification_number!: string;

  @observable customer_item_number!: number;

  @observable is_archived!: boolean;

  @observable specie!: Specie;

  @observable customer!: Customer;

  @observable juiceSpecificationsData!: JuiceSpecificationHistory[];

  @observable currentData!: JuiceSpecificationHistory;
}
