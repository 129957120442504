import { action, observable } from "mobx";
import { Designation } from "models/Designation";
import NewStore from "./NewStore";
import ApiRoutes from "routes/ApiRoutes";
import { ModelList } from "models/ModelList";

export class DesignationStore extends NewStore<Designation> {
  private static _instance: DesignationStore;

  constructor() {
    super();
    Designation._store = this;
  }

  static getInstance(): DesignationStore {
    if (!this._instance) {
      this._instance = new DesignationStore();
    }
    return this._instance;
  }

  @observable designations = new ModelList<Designation>(Designation);

  @action
  async fetchDesignations() {
    return this.designations.load(ApiRoutes.designations.list);
  }

  @action
  async createElement(name: string) {
    const response = await this.apiService.post(ApiRoutes.designations.list, {
      name,
    });
    const designation = Designation.fromJson(response.data) as Designation;
    this.designations.appendItem(designation);
    return designation;
  }

  @action
  async updateElement(designation: Designation, name: string) {
    try {
      designation.setUpdating(true);
      const response = await this.apiService.put(
        ApiRoutes.designations.show(designation.id),
        { name },
      );
      designation.setUpdating(false);
      designation.updateFromJson(response.data);
      return designation;
    } catch (e) {
      designation.setUpdating(false);
      throw e;
    }
  }

  @action
  async deleteElement(designation: Designation) {
    try {
      designation.setDeleting(true);
      await this.apiService.delete(ApiRoutes.designations.show(designation.id));
      designation.setDeleting(false);
      designation.delete();
    } catch (e) {
      designation.setDeleting(false);
      throw e;
    }
  }
}
