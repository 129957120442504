export const theme = {
  colors: {
    primaryColor: "rgb(16,193,206)",
    primaryDarkColor: "rgb(20,155,165)",
    surfSideRedColor: "rgb(206,16,61)",
    surfSideBlueColor: "rgb(0,47,84)",
    orangeColor: "rgb(226, 154, 46)",
    whiteColor: "rgb(255,255,255)",
    whiteColorOpacity: "rgba(255,255,255,0.7)",
    blackColor: "rgb(29,29,29)",
    greenColor: "rgb(16,206,79)",
    greyDarkColor: "rgb(126,126,126)",
    greyColor: "rgb(217,217,217)",
    greyLightColor: "rgb(245,245,245)",
    greyShadowColor: "rgb(240,239,255)",
    blackColorOpacity2: "rgba(29,29,29,0.2)",
    blackColorOpacity5: "rgba(29,29,29,0.5)",
    dividerColor: "rgba(112, 112, 112, 1)",
    scrollHover: "rgba(16,193,206,0.7)",
    dialogOverLay: "rgba(0, 0, 0, 0.7)",
  },
  material: {
    primary: {
      50: "#e2f8f9",
      100: "#b7ecf0",
      200: "#88e0e7",
      300: "#58d4dd",
      400: "#34cad5",
      500: "#10c1ce",
      600: "#0ebbc9",
      700: "#0cb3c2",
      800: "#09abbc",
      900: "#059eb0",
      A100: "#dbfbff",
      A200: "#a8f4ff",
      A400: "#75eeff",
      A700: "#5bebff",
      contrastDefaultColor: "dark",
    },
    secondary: {
      50: "#fcf3e6",
      100: "#f6e1c0",
      200: "#f1cd97",
      300: "#ebb86d",
      400: "#e6a94d",
      500: "#e29a2e",
      600: "#df9229",
      700: "#da8823",
      800: "#d67e1d",
      900: "#cf6c12",
      A100: "#fffefd",
      A200: "#ffe2ca",
      A400: "#ffc597",
      A700: "#ffb77e",
      contrastDefaultColor: "dark",
    },
    warning: {
      50: "#f9e2e8",
      100: "#f0b7c5",
      200: "#e7889e",
      300: "#dd5877",
      400: "#d5345a",
      500: "#ce103d",
      600: "#c90e37",
      700: "#c20c2f",
      800: "#bc0927",
      900: "#b0051a",
      A100: "#ffdbdd",
      A200: "#ffa8ae",
      A400: "#ff757f",
      A700: "#ff5b68",
      contrastDefaultColor: "light",
    },
  },
};
