import { action, computed, observable } from "mobx";

import NewModel from "./NewModel";
import { ModelList } from "./ModelList";

export interface LinksProps {
  first?: string;
  last?: string;
  prev?: string;
  next?: string;
}

export interface MetaProps {
  current_page: number;
  from: number;
  last_page: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
  search?: string;
}

export class PaginatedModelList<T extends NewModel, F = {}> extends ModelList<
  T,
  F
> {
  @observable isLoadingNextPage: boolean;

  @observable private _links?: LinksProps;

  @observable private _meta?: MetaProps;

  constructor(protected modelClass: typeof NewModel) {
    super(modelClass);
  }

  @computed
  get totalPages() {
    return this._meta?.total || 1;
  }

  @computed
  get lastPage() {
    return this._meta?.last_page || 1;
  }

  @computed
  get currentPage() {
    return this._meta?.current_page || 1;
  }

  @computed
  get firstPageUrl() {
    return this._links?.first || "";
  }

  @computed
  get lastPageUrl() {
    return this._links?.last || "";
  }

  @computed
  get isFirstPage() {
    return this.currentPage === 1;
  }

  @computed
  get isLastPage() {
    return this.lastPage === this.currentPage;
  }

  @computed
  get nextPage() {
    return this.currentPage + 1;
  }

  @computed
  get prevPage() {
    return this.currentPage - 1;
  }

  @computed
  get isLoadedAll() {
    return this.lastPage && this.loaded;
  }

  public async loadNext() {
    if (this.isLastPage) {
      return;
    }
    this.isLoadingNextPage = true;
    await super.load(
      this.url,
      { ...this.params, page: this.nextPage },
      { dataKey: null },
    );
  }

  public async load(url, params?: { [key: string]: any; page?: number }) {
    let newParams = { ...params };
    if (!params?.page) {
      newParams = { ...newParams, page: this.currentPage };
    }
    return super.load(url, newParams, { dataKey: null });
  }

  @action
  protected onSuccess(response) {
    this._meta = response.meta;
    this._links = response.links;
    super.onSuccess(response.data);
  }

  @action
  public deserialize(items: any[]) {
    if (!items) {
      this.loaded = true;
      return;
    }
    const models = items.map(item => this.modelClass.fromJson(item) as T);
    if (this.isLoadingNextPage) {
      this.appendItems(models);
      this.isLoadingNextPage = false;
    } else {
      this.setItems(models);
    }
    this.loaded = true;
  }
}
