import { action, computed, observable } from "mobx";
import { Specification } from "models/Specification";
import NewStore from "./NewStore";
import { ModelList } from "models/ModelList";
import { ModelItem } from "models/ModelItem";
import ApiRoutes from "routes/ApiRoutes";

export class SpecificationStore extends NewStore<Specification> {
  private static _instance: SpecificationStore;

  constructor() {
    super();
    Specification._store = this;
  }

  static getInstance(): SpecificationStore {
    if (!this._instance) {
      this._instance = new SpecificationStore();
    }
    return this._instance;
  }

  @observable specNumberList = new ModelList<Specification>(Specification);

  @observable specNumberItem = new ModelItem<Specification>(Specification);

  @computed get specNumbers() {
    return this.specNumberList.items;
  }

  @action
  async fetchSpecNumbers(params?: { [key: string]: any }) {
    return this.specNumberList.load(ApiRoutes.specNumbers.list, params);
  }

  @action
  async createElement(body: { [key: string]: any }) {
    const response = await this.apiService.post(
      ApiRoutes.specNumbers.list,
      body,
    );
    const specNumber = Specification.fromJson(response.data) as Specification;
    this.specNumberList.setItems([specNumber, ...this.specNumberList.items]);
    return specNumber;
  }

  @action
  async updateElement(specNumber: Specification, body: { [key: string]: any }) {
    try {
      specNumber.setUpdating(true);
      const response = await this.apiService.put(
        ApiRoutes.specNumbers.updateSpecHistoryData(specNumber.id),
        body,
      );

      specNumber.updateFromJson(response.data);

      specNumber.setUpdating(false);
      return specNumber;
    } catch (e) {
      specNumber.setUpdating(false);
      throw e;
    }
  }

  @action
  async deleteElement(specNumber: Specification) {
    try {
      specNumber.setDeleting(true);
      const body = {
        is_archived: !specNumber.is_archived,
      };
      const response = await this.apiService.put(
        ApiRoutes.specNumbers.show(specNumber.id),
        body,
      );
      specNumber.setDeleting(false);
      specNumber.updateFromJson(response.data);
      specNumber.delete();
    } catch (e) {
      specNumber.setDeleting(false);
      throw e;
    }
  }

  @action
  async fetchSpecNumberById(id: number) {
    return this.specNumberItem.load(ApiRoutes.specNumbers.show(id));
  }
}
