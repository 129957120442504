import { observable } from "mobx";
import { UserStore } from "stores/UserStore";
import { Designation } from "./Designation";
import NewModel from "./NewModel";

export class User extends NewModel {
  static _store: UserStore;

  @observable __type!: string;

  @observable id!: number;

  @observable name!: string;

  @observable status!: string;

  @observable created_at!: Date;

  @observable updated_at!: Date;

  @observable email!: string;

  @observable designation_id!: number;

  @observable phone_number!: string;

  @observable profile_pic_url!: string;

  @observable designation!: Designation;

  // deserializeRole_id(role_id: any) {
  //     console.log("user model");
  //     console.log(role_id);
  //     this.roleId = role_id;
  // }
}
