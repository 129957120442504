import { action, computed, observable } from "mobx";
import { GrindInspection } from "models/GrindInspection";
import { Dictionary } from "services/ApiService";
import { Bullpen } from "models/Bullpen";
import NewStore from "./NewStore";
import ApiRoutes from "routes/ApiRoutes";
import { ModelItem } from "models/ModelItem";
import { PaginatedModelList } from "models/PaginatedModelList";
import { GrindInspectionAddStatus } from "enums/GrindInspectionAddStatus";
import { format } from "date-fns";
import { Specification } from "models/Specification";
import { ModelList } from "models/ModelList";
import { toast } from "react-toastify";

export class GrindInspectionStore extends NewStore<GrindInspection> {
  private static _instance: GrindInspectionStore;

  @observable isCreatingBullpen: boolean = false;

  @observable isGeneratingBullpen: boolean = false;

  @observable isOptionsFetching: boolean = false;

  @observable isOptionsFetched: boolean = false;

  @observable isSpecificationsFetching: boolean = false;

  @observable isSpecificationsFetched: boolean = false;

  @observable grindInspectionCreated: GrindInspection | undefined;

  @observable bullpenNumber: string | undefined;

  @observable reworkBullpenList: Bullpen[] = [];

  @observable inspectionItem = new ModelItem<GrindInspection>(GrindInspection);

  @observable inspectionsList = new PaginatedModelList<GrindInspection>(
    GrindInspection,
  );

  @observable specificationList: Specification[] = [];

  @observable detailPageId: number = 0;

  @observable inspectionBoatId: number | undefined;

  @observable onGoingInspections = new ModelList<GrindInspection>(
    GrindInspection,
  );

  constructor() {
    super();
    GrindInspection._store = this;
    this.getInspectionBoatId();
    this.searchFilterParam.date = format(new Date(), "yyyy-MM-dd");
  }

  @computed get inspections() {
    return this.inspectionsList.items;
  }

  static getInstance(): GrindInspectionStore {
    if (!this._instance) {
      this._instance = new GrindInspectionStore();
    }
    return this._instance;
  }

  @action
  async fetchGrindInspections(params?: Dictionary<any>) {
    return this.inspectionsList.load(ApiRoutes.inspections.list, params);
  }

  @action
  async fetchInspectionById(id: number, creatingInspection?: boolean) {
    await this.inspectionItem.load(ApiRoutes.inspections.show(id));
    if (creatingInspection) {
      this.grindInspectionCreated = this.inspectionItem.item;
    }
  }

  @action
  async createGrindInspectionGroup(body: { [key: string]: any }) {
    const response = await this.apiService.post(
      ApiRoutes.inspections.list,
      body,
    );
    this.grindInspectionCreated = GrindInspection.fromJson(
      response.data,
    ) as GrindInspection;
    this.appendGrindInspectionObject(this.grindInspectionCreated!);
  }

  @action
  async createPastDateGrindInspectionGroup(body: { [key: string]: any }) {
    const response = await this.apiService.post(
      ApiRoutes.inspections.createGroupForPast,
      body,
    );

    this.grindInspectionCreated = GrindInspection.fromJson(
      response.data,
    ) as GrindInspection;
    this.appendGrindInspectionObject(this.grindInspectionCreated);
  }

  @action
  async generateBullpenNumber(id?: number) {
    try {
      this.isGeneratingBullpen = true;
      const response = await this.apiService.get(
        ApiRoutes.bullpen.generate(id ?? this.grindInspectionCreated!.id),
      );
      this.bullpenNumber = response.bullpen_number;
    } finally {
      this.isGeneratingBullpen = false;
    }
  }

  @action
  async generateBullpenNumberForPastDate(body) {
    try {
      this.isGeneratingBullpen = true;
      const response = await this.apiService.post(
        ApiRoutes.bullpen.generateNumberForPast,
        body
      );
      this.bullpenNumber = response.bullpen_number;
    } finally {
      this.isGeneratingBullpen = false;
    }
  }

  @action
  async fetchReworkBullpenList() {
    try {
      if (this.isOptionsFetching) {
        return;
      }
      this.isOptionsFetching = true;
      const response = await this.apiService.get(ApiRoutes.bullpen.fetchRework);
      this.reworkBullpenList = response.data;
      this.isOptionsFetched = true;
    } finally {
      this.isOptionsFetching = false;
    }
  }

  @action
  async createBullpen(body: { [key: string]: any }) {
    try {
      this.isCreatingBullpen = true;
      const response = await this.apiService.post(
        ApiRoutes.bullpen.create,
        body,
      );
      this.setInspectionBoatId(body.boat_id);
      this.grindInspectionCreated!.bullpens.push(response.data);
      GrindInspection.fromJson(this.grindInspectionCreated!);
      this.appendGrindInspectionObject(this.grindInspectionCreated!);
      await this.generateBullpenNumber();
      this.isCreatingBullpen = false;
    } catch (e) {
      this.isCreatingBullpen = false;
      throw e;
    }
  }

  @action
  async createBullpenForPast(body: { [key: string]: any }) {
    try {
      this.isCreatingBullpen = true;
      const response = await this.apiService.post(
        ApiRoutes.bullpen.createBullpenForPast,
        body,
      );
      this.setInspectionBoatId(body.boat_id);
      this.grindInspectionCreated!.bullpens.push(response.data);
      GrindInspection.fromJson(this.grindInspectionCreated!);
      this.appendGrindInspectionObject(this.grindInspectionCreated!);
      const bodyForBullpenNumber = { "bullpen_group_id": body.bullpen_group_id, "date": body.date };
      await this.generateBullpenNumberForPastDate(bodyForBullpenNumber);
      this.isCreatingBullpen = false;
    } catch (e) {
      this.isCreatingBullpen = false;
      throw e;
    }
  }

  @action
  async updateBullpenGroup(
    inspection: GrindInspection,
    body: { [key: string]: any },
  ) {
    try {
      inspection.setUpdating(true);
      const response = await this.apiService.put(
        ApiRoutes.inspections.show(inspection.id),
        body,
      );
      inspection.updateFromJson(response.data);
      inspection.setUpdating(false);
    } catch (e) {
      inspection.setUpdating(false);
      throw e;
    }
  }

  @action
  async updateBullpenInspection(
    inspectionId: number,
    bullpenId: number,
    bullpenGroupId: number,
    body: { [key: string]: any },
  ) {
    const response = await this.apiService.put(
      ApiRoutes.inspections.updateInspection(inspectionId),
      body,
    );
    const bullpenGroup = this!.get(+bullpenGroupId);
    const bullpen = bullpenGroup.bullpens.find(e => e.id === bullpenId);
    const bullpenInspectionIndex = bullpen.bullpenInspections.findIndex(
      e => e.id === inspectionId,
    );
    bullpen.bullpenInspections.splice(bullpenInspectionIndex, 1, response.data);
    bullpen.boat = response.data.bullpen.boat;
    bullpen.status = response.data.bullpen.status;
    bullpen.secondaryBoat = response.data.bullpen.secondaryBoat;
  }

  @action
  appendGrindInspectionObject(obj: GrindInspection) {
    let { items } = this.inspectionsList;
    const index = items.findIndex(element => element.id === obj.id);
    if (index !== undefined && index !== -1) {
      items.splice(index, 1, obj);
      this.inspectionsList.setItems(items);
    } else {
      items = [obj, ...items];
      this.inspectionsList.setItems(items);
    }
  }

  @action
  async fetchSearchedQuery(query?: { [key: string]: any }) {
    this.searchFilterParam = { ...this.searchFilterParam, ...query };
    await this.fetchGrindInspections(this.searchFilterParam);
    if (this.searchFilterParam.page) {
      delete this.searchFilterParam.page;
    }
  }

  @action
  deleteReworkBullpen(id: number) {
    this.reworkBullpenList = this.reworkBullpenList.filter(
      value => value.id !== id,
    );
  }

  @action
  updateBullpenStatus(bullpen: Bullpen, status: GrindInspectionAddStatus) {
    const inspection = this.inspectionsList.items.find(
      v => v.id === bullpen.bullpenGroup.id,
    );
    if (inspection) {
      const inspectionIndex = this.inspectionsList.items.findIndex(
        v => v.id === bullpen.bullpenGroup.id,
      );
      const updateBullpen = inspection.bullpens.find(e => e.id === bullpen.id);
      if (updateBullpen) {
        updateBullpen.status = status;
        const updateBullpenIndex = inspection.bullpens.findIndex(
          v => v.id === bullpen.id,
        );
        inspection.bullpens.splice(updateBullpenIndex, 1, updateBullpen);
        this.inspectionsList.items.splice(inspectionIndex, 1, inspection);
      } else {
        inspection.bullpens.push(bullpen);
      }
      this.inspectionsList.items.splice(inspectionIndex, 1, inspection);
    }
  }

  @action
  async fetchSpecifications() {
    try {
      if (this.isSpecificationsFetching) {
        return;
      }
      this.isSpecificationsFetching = true;
      const response = await this.apiService.get(
        ApiRoutes.inspections.listSpecifications,
      );
      this.specificationList = [...response.data];
      this.isSpecificationsFetched = true;
    } finally {
      this.isSpecificationsFetching = false;
    }
  }

  @action
  async fetchOnGoingInspections(
    params?: Dictionary<any>,
    forceRefresh?: boolean,
  ) {
    return this.onGoingInspections.load(ApiRoutes.inspections.ongoing, params, {
      forceRefresh: forceRefresh,
    });
  }

  @action
  async undoReworked(body) {
    try {
      await this.apiService.post(
        ApiRoutes.bullpen.derework,
        body
      );
    } catch (e) {
      toast.error(e.message)
    }
  }

  @action
  setDetailPageId(id: number) {
    this.detailPageId = id;
  }

  @action
  getDetailPageId() {
    return this.detailPageId;
  }

  @action
  setInspectionBoatId(id: number) {
    localStorage.setItem("inspection_boat_id", id.toString());
    this.inspectionBoatId = id;
  }

  @action
  getInspectionBoatId() {
    const id = localStorage.getItem("inspection_boat_id");
    this.inspectionBoatId = id === null ? undefined : +id;
  }
}
