import { observable } from "mobx";
import { Boat } from "./Boat";
import NewModel from "./NewModel";
import { Cooler } from "./Cooler";
import { JuiceBullpenInspection } from "./JuiceBullpenInspection";
import { JuiceInspection } from "./JuiceInspection";
import { PackingSize } from "./PackingSize";
import { GrindInspectionAddStatus } from "enums/GrindInspectionAddStatus";

export class JuiceBullpen extends NewModel {
  @observable id!: number;

  @observable status!: GrindInspectionAddStatus;

  @observable is_utilized!: boolean;

  @observable bullpen_number!: string;

  @observable packing_size!: PackingSize;

  @observable pack_time!: string;

  @observable created_at!: Date;

  @observable updated_at!: Date;

  @observable boat!: Boat;

  @observable secondaryBoat!: Boat;

  @observable cooler!: Cooler;

  @observable tank_number!: number;

  @observable bullpenInspections!: JuiceBullpenInspection[];

  @observable juiceBullpenGroup!: JuiceInspection;

  @observable carry_over_weight?: number;
}
