import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MaterialThemeProvider } from "@material-ui/styles";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { theme } from "./resources/colors";
import { materialTheme, Theme } from "./resources/theme";
import routes from "./routes";
import * as Sentry from "@sentry/react";

export interface GlobalProps {
  theme: Theme;
  children?: any;
}
// console.log(logRoutes());
const App: React.FC = () => {
  useEffect(() => {
    const networkDisconnectedHandler = () => {
      Sentry.addBreadcrumb({
        category: "Network",
        message: "Disconnected from Network",
        level: Sentry.Severity.Info,
        timestamp: Date.now(),
      });
    };

    const networkConnectedHandler = () => {
      Sentry.addBreadcrumb({
        category: "Network",
        message: "Connected to Network",
        level: Sentry.Severity.Info,
        timestamp: Date.now(),
      });
    };

    window.addEventListener("online", networkConnectedHandler);
    window.addEventListener("offline", networkDisconnectedHandler);

    return () => {
      window.removeEventListener("online", networkConnectedHandler);
      window.removeEventListener("offline", networkDisconnectedHandler);
    };
  }, []);

  return (
    <MaterialThemeProvider theme={materialTheme}>
      <ThemeProvider theme={theme}>{routes}</ThemeProvider>
    </MaterialThemeProvider>
  );
};

export default App;
