import styled from "styled-components";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
} from "@material-ui/core";
import React from "react";
import moment, { duration } from "moment";
import { ShiftBreak } from "models/ShiftBreak";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Utils } from "utils/utils";
import CheckIcon from "@material-ui/icons/Check";

interface FlexProps {
  flex: number;
}

const FlexContainer = styled.div<FlexProps>`
  flex: ${props => props.flex};
`;

interface SpaceYProps {
  height: string;
}

const SpaceY = styled.div<SpaceYProps>`
  height: ${props => props.height};
`;

interface SpaceXProps {
  width: string;
}

const SpaceX = styled.div<SpaceXProps>`
  width: ${props => props.width};
`;

const Divider = styled.div`
  width: 100%;
  height: 0.125rem;
  background-color: ${props => props.theme.colors.blackColorOpacity2};
  margin: 1.25rem 0;
  display: block;
`;

const ManageNewComponents = styled.div`
  flex: 4;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
  cursor: pointer;
  width: max-content;
`;

const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StepsRow = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
`;

const StepsCol = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
`;

const RowContainer = styled(ColumnContainer)`
  flex-direction: row;
  flex-grow: 1;
  position: relative;
`;

const FullRow = styled(RowContainer)`
  height: 100%;
`;

const FullPageColumn = styled(ColumnContainer)`
  height: 100%;
`;

const FullPageContainer = styled.div`
  text-align: center;
  background-color: ${props => props.theme.colors.whiteColor};
  width: 100vw;
  height: 100vh;
  display: flex;
  user-select: none;
`;

const StyledToastContainer = styled(ToastContainer).attrs({
  // custom props
})`
  .Toastify__toast--error {
    background-color: ${props => props.theme.colors.surfSideRedColor};
    font-size: 1.125rem;
    padding: 0.625rem 0.9375rem;
  }

  .Toastify__toast--success {
    font-size: 1.125rem;
    padding: 0.625rem 0.9375rem;
  }
`;

const StyledTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0 0.625rem;
`;

const StyledTableContainer = styled(TableContainer)`
  overflow-y: hidden;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 6.25rem;
    height: 0.5rem;
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.primaryColor};
    border-radius: 0.625rem;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${props => props.theme.colors.scrollHover};
  }
`;

const TableHeadCell = styled(TableCell)`
  height: 3.125rem;
  font-size: 1.25rem;
  box-shadow: none;
  border: none;
  color: ${props => props.theme.colors.whiteColor};
  background-color: ${props => props.theme.colors.primaryColor};
  font-weight: bold;
  padding: 0 1rem;
`;

const TableBodyCell = styled(TableCell)`
  height: 3.125rem;
  font-size: 1.25rem;
  box-shadow: none;
  border: none;
  background-color: ${props => props.theme.colors.whiteColor};
  padding: 0 1rem;
`;

const TableBodyCellBold = styled(TableBodyCell)`
  font-weight: bold;
`;

const GreyTableBodyCell = styled(TableBodyCell)`
  background-color: ${props => props.theme.colors.greyLightColor};
`;

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  ${TableHeadCell}:first-child {
    border-radius: 0.5rem 0 0 0.5rem;
  }
  ${TableHeadCell}:last-child {
    border-radius: 0 0.5rem 0.5rem 0;
  }

  ${TableBodyCell}:first-child {
    border-radius: 0.5rem 0 0 0.5rem;
  }
  ${TableBodyCell}:last-child {
    border-radius: 0 0.5rem 0.5rem 0;
  }
  @media print {
    page-break-inside: avoid;
  }
`;

const StyledTab = styled(Tab)`
  max-width: 100%;
`;

const StyledTabs = styled(props => (
  <Tabs
    {...props}
    classes={{ indicator: "indicator" }}
    TabIndicatorProps={{ children: <div /> }}
  />
))`
  .indicator {
    display: flex;
    justify-content: center;
    background-color: transparent;
    height: 0.375rem;
    & > div {
      border-radius: 0.5rem 0.5rem 0 0;
      width: 100%;
      max-width: 9.375rem;
      background-color: ${props => props.theme.colors.primaryColor};
    }
  }
`;

const TabLabel = styled.span`
  font-size: 1.25rem;
  color: ${props => props.theme.color};
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  align-self: flex-end;
`;

const SmallMobileView = styled.div`
  @media screen and (min-width: ${Utils.responsiveSize.smallMobile}) {
    display: none;
  }
`;

const NonSmallMobileView = styled.div`
  @media screen and (max-width: ${Utils.responsiveSize.smallMobile}) {
    display: none;
  }
`;

const IPadView = styled.div`
  display: none;
  @media screen and (max-width: ${Utils.responsiveSize.customTab}) {
    display: inherit;
  }
`;

const NonIPadView = styled.div`
  @media screen and (max-width: ${Utils.responsiveSize.customTab}) {
    display: none;
  }
`;

const IPadProView = styled.div`
  display: none;
  @media screen and (max-width: ${Utils.responsiveSize.ipadPro}) {
    display: inherit;
  }
`;

const NonIPadProView = styled.div`
  @media screen and (max-width: ${Utils.responsiveSize.ipadPro}) {
    display: none;
  }
`;

const XLSizeView = styled.div`
  @media screen and (max-width: ${Utils.responsiveSize.xl}) {
    display: none;
  }
`;

const NonXLSizeView = styled.div`
  @media screen and (min-width: ${Utils.responsiveSize.xl}) {
    display: none;
  }
`;

const StyledRow = styled(RowContainer)`
  min-height: 35rem;
  align-items: center;
  justify-content: center;
`;

const PrintColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media print {
    display: block;
  }
`;

const PrintBottomDiv = styled.div`
  @media print {
    page-break-before: always;
  }
`;

const StyledPlusIcon = styled(AddCircleIcon)`
  color: ${props => props.theme.colors.greenColor};
  font-size: 2rem;
  margin-top: 0.625rem;
  cursor: pointer;
`;

const StyledUpdateIcon = styled(EditIcon)`
  color: ${props => props.theme.colors.greenColor};
  font-size: 2rem;
  margin-top: 0.625rem;
  cursor: pointer;
`;

const CrossIconContainer = styled(CancelRoundedIcon)`
  color: ${props => props.theme.colors.surfSideRedColor};
  font-size: 2rem;
  margin-top: 0.625rem;
  cursor: pointer;
`;

const StyledPrintPreview = styled.div`
  background-color: ${props => props.theme.colors.greyLightColor};
  display: none;
  @media print {
    display: inherit;
  }
`;

const StyledCheckBox = styled(CheckBoxIcon)`
  color: ${props => props.theme.colors.primaryColor};
  font-size: 1.5625rem;
`;

const StyledBlankCheckBox = styled(CheckBoxOutlineBlankIcon)`
  color: ${props => props.theme.colors.primaryColor};
  font-size: 1.5625rem;
`;

const CheckIconContainer = styled(CheckIcon)`
  color: ${props => props.theme.colors.whiteColor};
  font-size: 1.875rem;
  margin-top: 0.625rem;
  cursor: pointer;
`;

const DialogChild = styled.div`
  min-width: 35rem;
  text-align: end;
`;

const capitalize = (name: string) => {
  name = name?.split("")[0].toUpperCase() + name?.substr(1);
  return name;
};

const titleCase = (str: string) => {
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

const getGreaterLessSymbol = data => {
  let result = "";
  if (data === "More than" || data === "more_than") {
    result = "> ";
  } else if (data === "Less than" || data === "less_than") {
    result = "< ";
  } else if (data === "Less than equal to" || data === "less_than_equal_to") {
    result = "<= ";
  } else if (data === "More than equal to" || data === "more_than_equal_to") {
    result = ">= ";
  } else {
    result = "";
  }

  return result;
};

const getUnitWithValue = data => {
  let returnString = "No data";
  if (data) {
    returnString = "";
    returnString += getGreaterLessSymbol(data.condition);
    returnString += `${data.count}/`;
    returnString += `${data.unit} `;
    if (data?.remark) {
      returnString += data.remark;
    }
  }

  return returnString;
};

const getTimeInAmPm = (date: Date) => {
  if (date) {
    return moment(date).format("hh:mm A");
  }
  return "-";
};

const getDuration = (breakTime: ShiftBreak[], condition: string) => {
  let totalDurations = 0;
  breakTime.forEach(time => {
    if (time.type === condition && time.end_time !== null) {
      const startTime = moment(time.start_time);
      const endTime = moment(time.end_time);
      const diff = endTime.diff(startTime);
      totalDurations += diff;
    }
  });
  const hrs = +duration(totalDurations).asHours().toFixed(0);
  const min = +(duration(totalDurations).asMinutes() % 60).toFixed(0);
  return hrs ? `${hrs} Hours ${min} Mins` : `${min} Minutes`;
};

const totalCount = (summaryData): number => {
  let count = 0;
  if (summaryData) {
    const { general } = summaryData;
    count =
      general?.supervisor_count +
      general?.production_count +
      general?.maintenance_count +
      general?.qc_count;
  }
  return count;
};

const roundOffDecimal = (data: number): string => data.toFixed(2);

export {
  SpaceX,
  Divider,
  SpaceY,
  ManageNewComponents,
  StyledLink,
  ColumnContainer,
  RowContainer,
  FullRow,
  FullPageColumn,
  FullPageContainer,
  StyledToastContainer,
  StyledTable,
  StyledTableContainer,
  TableHeadCell,
  TableBodyCell,
  GreyTableBodyCell,
  StyledTableRow,
  FlexContainer,
  TabLabel,
  StyledTabs,
  TabContainer,
  StyledTab,
  SmallMobileView,
  NonSmallMobileView,
  IPadView,
  NonIPadView,
  IPadProView,
  NonIPadProView,
  XLSizeView,
  NonXLSizeView,
  StyledRow,
  PrintColumnContainer,
  PrintBottomDiv,
  StyledUpdateIcon,
  CrossIconContainer,
  StyledPrintPreview,
  StyledCheckBox,
  StyledBlankCheckBox,
  StyledPlusIcon,
  DialogChild,
  capitalize,
  getUnitWithValue,
  getGreaterLessSymbol,
  getTimeInAmPm,
  titleCase,
  getDuration,
  totalCount,
  roundOffDecimal,
  CheckIconContainer,
  StepsRow,
  StepsCol,
  TableBodyCellBold,
};
