import { action, computed, observable } from "mobx";
import { Cooler } from "models/Cooler";
import NewStore from "./NewStore";
import { ModelList } from "models/ModelList";
import { ModelItem } from "models/ModelItem";
import ApiRoutes from "routes/ApiRoutes";

export class CoolerStore extends NewStore<Cooler> {
  private static _instance: CoolerStore;

  constructor() {
    super();
    Cooler._store = this;
  }

  static getInstance(): CoolerStore {
    if (!this._instance) {
      this._instance = new CoolerStore();
    }
    return this._instance;
  }

  @observable coolerList = new ModelList<Cooler>(Cooler);

  @observable coolerItem = new ModelItem<Cooler>(Cooler);

  @observable clientCoolerList = new ModelList<Cooler>(Cooler);

  @computed get coolers() {
    return this.coolerList.items.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );
  }

  @computed get activeCoolers() {
    return this.coolers.filter(cooler => !cooler.is_archived);
  }

  @computed get inActiveCoolers() {
    return this.coolers.filter(cooler => cooler.is_archived);
  }

  @computed get surfsideCoolers() {
    return this.coolers.filter(cooler => cooler.customer === null);
  }

  @action
  async fetchCoolers(
    params?: { [key: string]: any },
    config?: { [key: string]: any },
  ) {
    return this.coolerList.load(ApiRoutes.coolers.list, params, config);
  }

  @action
  async createElement(name, id?: number) {
    const body: { [key: string]: any } = {};
    body.name = name;
    if (id) {
      body.customer_id = id;
    }
    const response = await this.apiService.post(ApiRoutes.coolers.list, body);
    const cooler = Cooler.fromJson(response.data) as Cooler;
    this.coolerList.appendItem(cooler);
    return cooler;
  }

  @action
  async updateElement(cooler: Cooler, body: { [key: string]: any }) {
    try {
      cooler.setUpdating(true);
      const response = await this.apiService.put(
        ApiRoutes.coolers.show(cooler.id),
        body,
      );
      cooler.setUpdating(false);
      cooler.updateFromJson(response.data);
      return cooler;
    } catch (e) {
      cooler.setUpdating(false);
      throw e;
    }
  }

  @action
  async deleteElement(cooler: Cooler) {
    try {
      cooler.setDeleting(true);
      await this.apiService.delete(ApiRoutes.coolers.show(cooler.id));
      cooler.setDeleting(false);
      cooler.delete();
    } catch (e) {
      cooler.setDeleting(false);
      throw e;
    }
  }

  @action
  async fetchClientCoolers(
    params?: { [key: string]: any },
    config?: { [key: string]: any },
  ) {
    return this.clientCoolerList.load(
      ApiRoutes.coolers.getClientCoolers,
      params,
      config,
    );
  }
}
