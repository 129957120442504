import NewModel from "./NewModel";
import { observable } from "mobx";
import { User } from "./UserModel";
import { BullpenGroupStatusTypes } from "enums/BullpenGroupStatusTypes";
import { JuiceSpecification } from "./JuiceSpecification";
import { JuiceBullpen } from "./JuiceBullpen";

export class JuiceBullpenGroup extends NewModel {
  @observable id!: number;

  @observable juice_temperature!: number;

  @observable condition_notes!: string;

  @observable created_at!: Date;

  @observable updated_at!: Date;

  @observable creator!: User;

  @observable specifications!: JuiceSpecification;

  @observable juiceBullpens!: JuiceBullpen[];

  @observable status: BullpenGroupStatusTypes;

}
