import { action, computed, observable } from "mobx";
import { Dictionary } from "services/ApiService";
import NewStore from "./NewStore";
import ApiRoutes from "routes/ApiRoutes";
import { ModelItem } from "models/ModelItem";
import { PaginatedModelList } from "models/PaginatedModelList";
import { GrindInspectionAddStatus } from "enums/GrindInspectionAddStatus";
import { format } from "date-fns";
import { ModelList } from "models/ModelList";
import { JuiceInspection } from "models/JuiceInspection";
import { JuiceSpecification } from "models/JuiceSpecification";
import { JuiceBullpen } from "models/JuiceBullpen";

export class JuiceInspectionStore extends NewStore<JuiceInspection> {
  private static _instance: JuiceInspectionStore;

  @observable isCreatingBullpen: boolean = false;

  @observable isGeneratingBullpen: boolean = false;

  @observable isOptionsFetching: boolean = false;

  @observable isOptionsFetched: boolean = false;

  @observable isSpecificationsFetching: boolean = false;

  @observable isSpecificationsFetched: boolean = false;

  @observable juiceInspectionCreated: JuiceInspection | undefined;

  @observable juiceBullpenNumber: string | undefined;

  @observable reworkBullpenList: JuiceBullpen[] = [];

  @observable inspectionItem = new ModelItem<JuiceInspection>(JuiceInspection);

  @observable inspectionsList = new PaginatedModelList<JuiceInspection>(
    JuiceInspection,
  );

  @observable specificationList: JuiceSpecification[] = [];

  @observable detailPageId: number = 0;

  @observable inspectionBoatId: number | undefined;

  @observable onGoingInspections = new ModelList<JuiceInspection>(
    JuiceInspection,
  );

  @observable bullpenStatus: string = "";

  @observable latestTankNumber: number = 1;

  constructor() {
    super();
    JuiceInspection._store = this;
    this.getInspectionBoatId();
    this.searchFilterParam.date = format(new Date(), "yyyy-MM-dd");
  }

  @computed get inspections() {
    return this.inspectionsList.items;
  }

  static getInstance(): JuiceInspectionStore {
    if (!this._instance) {
      this._instance = new JuiceInspectionStore();
    }
    return this._instance;
  }

  @action
  async fetchJuiceInspections(params?: Dictionary<any>) {
    return this.inspectionsList.load(ApiRoutes.juiceInspections.list, params);
  }

  @action
  async fetchInspectionById(id: number, creatingInspection?: boolean) {
    await this.inspectionItem.load(ApiRoutes.juiceInspections.show(id));
    if (creatingInspection) {
      this.juiceInspectionCreated = this.inspectionItem.item;
    }
  }

  @action
  async createJuiceInspectionGroup(body: { [key: string]: any }) {
    const response = await this.apiService.post(
      ApiRoutes.juiceInspections.list,
      body,
    );
    this.bullpenStatus = response.data.status;
    this.juiceInspectionCreated = JuiceInspection.fromJson(
      response.data,
    ) as JuiceInspection;
    this.appendJuiceInspectionObject(this.juiceInspectionCreated!);
  }

  @action
  async createPastDateJuiceInspectionGroup(body: { [key: string]: any }) {
    const response = await this.apiService.post(
      ApiRoutes.juiceInspections.createGroupForPast,
      body,
    );
    this.bullpenStatus = response.data.status;
    this.juiceInspectionCreated = JuiceInspection.fromJson(
      response.data,
    ) as JuiceInspection;
    this.appendJuiceInspectionObject(this.juiceInspectionCreated);
  }

  @action
  async generateJuiceBullpenNumber(body) {
    try {
      this.isGeneratingBullpen = true;
      const response = await this.apiService.post(
        ApiRoutes.juiceBullpen.generate,
        body,
      );
      this.juiceBullpenNumber = response.bullpen_number;
    } finally {
      this.isGeneratingBullpen = false;
    }
  }

  @action
  async generateJuiceBullpenNumberForPastDate(body) {
    try {
      this.isGeneratingBullpen = true;
      const response = await this.apiService.post(
        ApiRoutes.juiceBullpen.generateNumberForPast,
        body,
      );
      this.juiceBullpenNumber = response.bullpen_number;
    } finally {
      this.isGeneratingBullpen = false;
    }
  }

  @action
  async fetchReworkBullpenList() {
    try {
      if (this.isOptionsFetching) {
        return;
      }
      this.isOptionsFetching = true;
      const response = await this.apiService.get(
        ApiRoutes.juiceBullpen.fetchRework,
      );
      this.reworkBullpenList = response.data;
      this.isOptionsFetched = true;
    } finally {
      this.isOptionsFetching = false;
    }
  }

  @action
  async createBullpen(body: { [key: string]: any }) {
    delete body.tank_2_same;
    let newBody = { ...body };
    if (body.tank_number === 2) {
      newBody = {
        ...body,
        second_tank_bullpen_number: this.juiceBullpenNumber,
      };
    }
    try {
      this.isCreatingBullpen = true;
      const response = await this.apiService.post(
        ApiRoutes.juiceBullpen.create,
        newBody,
      );
      this.setInspectionBoatId(body.boat_id);
      this.bullpenStatus = body.status;
      if (body.tank_number === 2) {
        this.latestTankNumber = 0;
      } else {
        this.latestTankNumber = 2;
      }
      this.juiceInspectionCreated!.juiceBullpens.push(response.data);
      JuiceInspection.fromJson(this.juiceInspectionCreated!);
      this.appendJuiceInspectionObject(this.juiceInspectionCreated!);
      this.isCreatingBullpen = false;
    } catch (e) {
      this.isCreatingBullpen = false;
      throw e;
    }
  }

  @action
  async createBullpenForPast(body: { [key: string]: any }) {
    delete body.tank_2_same;
    let newBody = { ...body };
    if (body.tank_number === 2) {
      newBody = {
        ...body,
        second_tank_bullpen_number: this.juiceBullpenNumber,
      };
    }
    try {
      this.isCreatingBullpen = true;
      const response = await this.apiService.post(
        ApiRoutes.juiceBullpen.createBullpenForPast,
        newBody,
      );
      this.setInspectionBoatId(body.boat_id);
      this.bullpenStatus = body.status;
      if (body.tank_number === 2) {
        this.latestTankNumber = 0;
      } else {
        this.latestTankNumber = 2;
      }
      this.juiceInspectionCreated!.juiceBullpens.push(response.data);
      JuiceInspection.fromJson(this.juiceInspectionCreated!);
      this.appendJuiceInspectionObject(this.juiceInspectionCreated!);
      this.isCreatingBullpen = false;
    } catch (e) {
      this.isCreatingBullpen = false;
      throw e;
    }
  }

  @action
  async updateBullpenGroup(
    inspection: JuiceInspection,
    body: { [key: string]: any },
  ) {
    try {
      inspection.setUpdating(true);
      const response = await this.apiService.put(
        ApiRoutes.juiceInspections.show(inspection.id),
        body,
      );
      inspection.updateFromJson(response.data);
      inspection.setUpdating(false);
    } catch (e) {
      inspection.setUpdating(false);
      throw e;
    }
  }

  @action
  async updateBullpenInspection(
    inspectionId: number,
    bullpenId: number,
    bullpenGroupId: number,
    body: { [key: string]: any },
  ) {
    const response = await this.apiService.put(
      ApiRoutes.juiceInspections.updateInspection(inspectionId),
      body,
    );
    const bullpenGroup = this!.get(+bullpenGroupId);
    const bullpen = bullpenGroup.juiceBullpens.find(e => e.id === bullpenId);
    const bullpenInspectionIndex = bullpen.bullpenInspections.findIndex(
      e => e.id === inspectionId,
    );
    bullpen.bullpenInspections.splice(bullpenInspectionIndex, 1, response.data);
    bullpen.boat = response.data.bullpen.boat;
    bullpen.status = response.data.bullpen.status;
  }

  @action
  appendJuiceInspectionObject(obj: JuiceInspection) {
    let { items } = this.inspectionsList;
    const index = items.findIndex(element => element.id === obj.id);
    if (index !== undefined && index !== -1) {
      items.splice(index, 1, obj);
      this.inspectionsList.setItems(items);
    } else {
      items = [obj, ...items];
      this.inspectionsList.setItems(items);
    }
  }

  @action
  async fetchSearchedQuery(query?: { [key: string]: any }) {
    this.searchFilterParam = { ...this.searchFilterParam, ...query };
    await this.fetchJuiceInspections(this.searchFilterParam);
    if (this.searchFilterParam.page) {
      delete this.searchFilterParam.page;
    }
  }

  @action
  deleteReworkBullpen(id: number) {
    this.reworkBullpenList = this.reworkBullpenList.filter(
      value => value.id !== id,
    );
  }

  @action
  updateBullpenStatus(bullpen: JuiceBullpen, status: GrindInspectionAddStatus) {
    const inspection = this.inspectionsList.items.find(
      v => v.id === bullpen.juiceBullpenGroup.id,
    );
    if (inspection) {
      const inspectionIndex = this.inspectionsList.items.findIndex(
        v => v.id === bullpen.juiceBullpenGroup.id,
      );
      const updateBullpen = inspection.juiceBullpens.find(
        e => e.id === bullpen.id,
      );
      if (updateBullpen) {
        updateBullpen.status = status;
        const updateBullpenIndex = inspection.juiceBullpens.findIndex(
          v => v.id === bullpen.id,
        );
        inspection.juiceBullpens.splice(updateBullpenIndex, 1, updateBullpen);
        this.inspectionsList.items.splice(inspectionIndex, 1, inspection);
      } else {
        inspection.juiceBullpens.push(bullpen);
      }
      this.inspectionsList.items.splice(inspectionIndex, 1, inspection);
    }
  }

  @action
  async fetchSpecifications() {
    try {
      if (this.isSpecificationsFetching) {
        return;
      }
      this.isSpecificationsFetching = true;
      const response = await this.apiService.get(
        ApiRoutes.juiceInspections.listSpecifications,
      );
      this.specificationList = [...response.data];
      this.isSpecificationsFetched = true;
    } finally {
      this.isSpecificationsFetching = false;
    }
  }

  @action
  async fetchOnGoingInspections(
    params?: Dictionary<any>,
    forceRefresh?: boolean,
  ) {
    return this.onGoingInspections.load(
      ApiRoutes.juiceInspections.ongoing,
      { params },
      {
        forceRefresh: forceRefresh,
      },
    );
  }

  @action
  setDetailPageId(id: number) {
    this.detailPageId = id;
  }

  @action
  getDetailPageId() {
    return this.detailPageId;
  }

  @action
  setInspectionBoatId(id: number) {
    localStorage.setItem("inspection_boat_id", id.toString());
    this.inspectionBoatId = id;
  }

  @action
  getInspectionBoatId() {
    const id = localStorage.getItem("inspection_boat_id");
    this.inspectionBoatId = id === null ? undefined : +id;
  }
}
