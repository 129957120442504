import { action, computed, observable } from "mobx";
import { ModelList } from "models/ModelList";
import { HarvestLocation } from "models/HarvestLocation";
import { ModelItem } from "models/ModelItem";
import ApiRoutes from "routes/ApiRoutes";
import NewStore from "./NewStore";

export class HarvestLocationStore extends NewStore<HarvestLocation> {
  private static _instance: HarvestLocationStore;

  constructor() {
    super();
    HarvestLocation._store = this;
  }

  static getInstance(): HarvestLocationStore {
    if (!this._instance) {
      this._instance = new HarvestLocationStore();
    }
    return this._instance;
  }

  @observable harvestLocationList = new ModelList<HarvestLocation>(
    HarvestLocation,
  );

  @observable harvestLocationItem = new ModelItem<HarvestLocation>(
    HarvestLocation,
  );

  @computed get harvestLocations() {
    return this.harvestLocationList.items.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );
  }

  @computed get activeHarvestLocations() {
    return this.harvestLocations.filter(
      harvestLocation => !harvestLocation.is_archived,
    );
  }

  @computed get inActiveHarvestLocations() {
    return this.harvestLocations.filter(
      harvestLocation => harvestLocation.is_archived,
    );
  }

  @action
  async fetchHarvestLocations(params?: { [key: string]: any }) {
    return this.harvestLocationList.load(
      ApiRoutes.harvestLocations.list,
      params,
    );
  }

  @action
  async createElement(name) {
    const response = await this.apiService.post(
      ApiRoutes.harvestLocations.list,
      { name },
    );
    const harvestLocation = HarvestLocation.fromJson(
      response.data,
    ) as HarvestLocation;
    this.harvestLocationList.appendItem(harvestLocation);
    return harvestLocation;
  }

  @action
  async updateElement(
    harvestLocation: HarvestLocation,
    body: { [key: string]: any },
  ) {
    try {
      harvestLocation.setUpdating(true);
      const response = await this.apiService.put(
        ApiRoutes.harvestLocations.show(harvestLocation.id),
        body,
      );
      harvestLocation.setUpdating(false);
      harvestLocation.updateFromJson(response.data);
      return harvestLocation;
    } catch (e) {
      harvestLocation.setUpdating(false);
      throw e;
    }
  }

  @action
  async deleteElement(harvestLocation: HarvestLocation) {
    try {
      harvestLocation.setDeleting(true);
      await this.apiService.delete(
        ApiRoutes.harvestLocations.show(harvestLocation.id),
      );
      harvestLocation.setDeleting(false);
      harvestLocation.delete();
    } catch (e) {
      harvestLocation.setDeleting(false);
      throw e;
    }
  }

  @action
  async fetchHarvestLocationFromId(id: number) {
    return this.harvestLocationItem.load(ApiRoutes.harvestLocations.show(id));
  }
}
