import { observable } from "mobx";
import { PermissionStore } from "stores/PermissionStore";
import { PermissionArea } from "enums/permission.enum";
import NewModel from "./NewModel";

export class Permission extends NewModel {
  static _store: PermissionStore;

  @observable id!: number;

  @observable name!: string;

  @observable slug!: PermissionArea;
}
