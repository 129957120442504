import { action, computed, observable } from "mobx";
import { BullpenSize } from "models/BullpenSize";
import NewStore from "./NewStore";
import { ModelList } from "models/ModelList";
import ApiRoutes from "routes/ApiRoutes";
import { ModelItem } from "models/ModelItem";

export class BullpenSizeStore extends NewStore<BullpenSize> {
  private static _instance: BullpenSizeStore;

  @observable bullpenSizeItem = new ModelItem<BullpenSize>(BullpenSize);

  @observable bullpenSizesList = new ModelList<BullpenSize>(BullpenSize);

  constructor() {
    super();
    BullpenSize._store = this;
  }

  static getInstance(): BullpenSizeStore {
    if (!this._instance) {
      this._instance = new BullpenSizeStore();
    }
    return this._instance;
  }

  @computed get bullpenSizes() {
    return this.bullpenSizesList.items.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );
  }

  @computed get bullpenSize() {
    return this.bullpenSize.item;
  }

  @computed get activeBullpenSizes() {
    return this.bullpenSizes.filter(e => !e.is_archived);
  }

  @computed get inActiveBullpenSizes() {
    return this.bullpenSizes.filter(e => e.is_archived);
  }

  @action
  async fetchBullpenSizes(params?: { [key: string]: any }) {
    return this.bullpenSizesList.load(ApiRoutes.bullpenSizes.list, params);
  }

  @action
  async createElement(name: string) {
    const response = await this.apiService.post(ApiRoutes.bullpenSizes.list, {
      name,
    });
    const bullpenSize = BullpenSize.fromJson(response.data) as BullpenSize;
    this.bullpenSizesList.appendItem(bullpenSize);
    return bullpenSize;
  }

  @action
  async updateElement(bullpenSize: BullpenSize, body: { [key: string]: any }) {
    try {
      bullpenSize.setUpdating(true);
      const response = await this.apiService.put(
        ApiRoutes.bullpenSizes.show(bullpenSize.id),
        body,
      );
      bullpenSize.setUpdating(false);
      bullpenSize.updateFromJson(response.data);
      return bullpenSize;
    } catch (e) {
      bullpenSize.setUpdating(false);
      throw e;
    }
  }

  @action
  async deleteElement(bullpenSize: BullpenSize) {
    try {
      bullpenSize.setDeleting(true);
      await this.apiService.delete(ApiRoutes.bullpenSizes.show(bullpenSize.id));
      bullpenSize.setDeleting(false);
      bullpenSize.delete();
    } catch (e) {
      bullpenSize.setDeleting(false);
      throw e;
    }
  }

  @action
  async fetchBullpenSizeFromId(id: number) {
    return this.bullpenSizeItem.load(ApiRoutes.bullpenSizes.show(id));
  }
}
