import React from "react";
import Loader from "components/shared/loader/Loader";
import { FullPageContainer } from "components/globals";

interface Props {}

const FullScreenLoader: React.FC<Props> = (props: Props) => (
  <FullPageContainer>
    <Loader />
  </FullPageContainer>
);

export default FullScreenLoader;
