import { ApproveJuiceBullpensStore } from "stores/ApproveJuiceBullpenStore";
import { JuiceBullpen } from "./JuiceBullpen";
import { JuiceBullpenInspection } from "./JuiceBullpenInspection";
import { observable } from "mobx";

export class WaitingApprovalJuiceBullpen extends JuiceBullpen {
  static _store: ApproveJuiceBullpensStore;

  @observable bullpenInspections!: JuiceBullpenInspection[];
  @observable bullpen_number!: string;
}
