import { action, computed, IObservableArray, observable } from "mobx";
import { ModelContainer } from "./ModelContainer";
import NewModel from "./NewModel";
import { uniqBy } from "lodash";

export class ModelList<T extends NewModel, F = {}> extends ModelContainer<
  T,
  F
> {
  @observable protected _items: IObservableArray<T>;

  constructor(protected modelClass: typeof NewModel) {
    super(modelClass);
    this._items = observable([]);
  }

  @computed
  get hasError() {
    return !!this.error;
  }

  @computed
  get items() {
    return this._items.filter(item => !item.deleted);
  }

  @action
  setItems(items: T[]) {
    this._items.clear();
    this.appendItems(items);
  }

  @action
  appendItems(items: T[]) {
    const arr1 = this._items;
    arr1.push(...items);

    const arr2 = uniqBy(arr1, "id");

    this._items.clear();
    this._items.push(...arr2);
  }

  @action
  appendItem(item: T) {
    this._items.push(item);
  }

  @action
  removeItem(item: T) {
    const index = this._items.findIndex((i) => i?.id === item?.id);

    if (index !== -1) {
      this._items.splice(index, 1);
    }
  }

  @action
  public deserialize(items: any[]) {
    if (!items) {
      this.loaded = true;
      return;
    }
    const models = items.map(item => this.modelClass.fromJson(item) as T);
    this.setItems(models);
    this.loaded = true;
  }
}
