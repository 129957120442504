import { AxiosError } from "axios";
import { titleCase } from "components/globals";

export enum ErrorCode {
  UNIDENTIFIED,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  REQUEST_TIMEOUT = 408,
  CONFLICT_REQUEST = 409,
  UNPROCESSABLE_ENTITY = 422,
  TOO_MANY_REQUESTS = 429,
  REQUEST_CLOSED = 499,
  VALIDATION_ERROR = 9998,
}

export class SurfSideError {
  message: string;

  status: ErrorCode;

  errors: { [key: string]: any };

  data: { [key: string]: any };

  url: string | undefined;

  userId: string;

  public constructor(
    message: string = "Error. Please Try Again.",
    status: ErrorCode = ErrorCode.UNIDENTIFIED,
    url: string,
    errors = {},
    data = {},
  ) {
    this.message = message;
    this.status = status;
    this.url = url;
    this.errors = errors;
    this.data = data;
    this.userId = localStorage.getItem("user_id");
  }

  private static getMessageForResponseCode(
    code: ErrorCode,
    altMessage?: string,
  ): string {
    if (code === ErrorCode.VALIDATION_ERROR) {
      return "Validation Error";
    }
    return altMessage;
  }

  private static getCompleteMessage(data) {
    let msg = `${SurfSideError.getMessageForResponseCode(
      data.code,
      data.message,
    )} : `;
    data.errors.forEach((error, index) => {
      let { dataPath } = error;
      const { params } = error;
      if (dataPath.length !== 0) {
        dataPath = dataPath.substr(1);
        msg += titleCase(dataPath.split("_").join(" "));
      } else {
        const value: string = Object.values(params)[0] as string;
        msg += titleCase(value.split("_").join(" "));
      }
      if (index !== data.errors.length - 1) {
        msg += ", ";
      } else {
        msg += "";
      }
    });
    return msg;
  }

  static from(axiosError: AxiosError, url: string | undefined): SurfSideError {
    if (!axiosError.message) {
      return new SurfSideError(
        "Error. Api Cancelled",
        ErrorCode.REQUEST_CLOSED,
        url,
      );
    }

    if (axiosError.message.includes("timeout")) {
      return new SurfSideError(
        "Request Timeout. Please Check Your Internet",
        ErrorCode.REQUEST_TIMEOUT,
        url,
      );
    }

    if (!axiosError.response) {
      return new SurfSideError(undefined, undefined, url);
    }

    const { status, data } = axiosError.response;
    if (status === ErrorCode.UNPROCESSABLE_ENTITY) {
      return new SurfSideError(
        SurfSideError.getCompleteMessage(data),
        status,
        url,
        data?.errors,
        data?.data,
      );
    }
    return new SurfSideError(data?.message, status, null, data?.data);
  }

  errorsByKey(key: string) {
    return this.errors?.[key];
  }

  hasErrorByKey(key: string) {
    return !!this.errorsByKey(key);
  }
}
