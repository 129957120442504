import { Permission } from "models/Permission";
import { action, computed, observable } from "mobx";
import NewStore from "./NewStore";
import ApiRoutes from "routes/ApiRoutes";

export class PermissionStore extends NewStore<Permission> {
  private static _instance: PermissionStore;

  @observable isLoading: boolean = false;

  @observable permissionList: Permission[] = [];

  constructor() {
    super();
    Permission._store = this;
  }

  static getInstance(): PermissionStore {
    if (!this._instance) {
      this._instance = new PermissionStore();
    }
    return this._instance;
  }

  @computed get permissions() {
    return this.permissionList;
  }

  @action
  async fetchPermissions() {
    try {
      this.isLoading = true;
      const response = await this.apiService.get(
        ApiRoutes.designations.permissions,
      );
      const permissionResponse: Permission[] = response.data;
      this.permissionList = [];
      permissionResponse.forEach(permission => {
        this.permissionList.push(permission);
      });
    } finally {
      this.isLoading = false;
    }
  }
}
