import NewStore from "./NewStore";
import { action, computed, observable } from "mobx";
import { ModelList } from "models/ModelList";
import ApiRoutes from "routes/ApiRoutes";
import moment from "moment";
import { WaitingApprovalJuiceBullpen } from "models/WaitingApprovalJuiceBullpen";

export class ApproveJuiceBullpensStore extends NewStore<WaitingApprovalJuiceBullpen> {
    private static _instance: ApproveJuiceBullpensStore;

    @observable bullpensList = new ModelList<WaitingApprovalJuiceBullpen>(
        WaitingApprovalJuiceBullpen,
    );

    constructor() {
        super();
        WaitingApprovalJuiceBullpen._store = this;
    }

    @computed get bullpens() {
        return this.bullpensList.items;
    }

    static getInstance(): ApproveJuiceBullpensStore {
        if (!this._instance) {
            this._instance = new ApproveJuiceBullpensStore();
        }
        return this._instance;
    }

    @action
    async fetchWaitingForApprovalBullpens(
        params?: { [key: string]: any },
        config?: { [key: string]: any },
    ) {
        return this.bullpensList.load(
            ApiRoutes.approveJuiceBullpens.fetch,
            params,
            config,
        );
    }

    @action
    async approveBullpen(obj: WaitingApprovalJuiceBullpen) {
        try {
            obj.setDeleting(true);
            await this.apiService.post(ApiRoutes.approveJuiceBullpens.approve(obj.id));
            obj.setDeleting(false);
            obj.delete();
        } catch (e) {
            obj.setDeleting(false);
            throw e;
        }
    }

    @action
    async rejectBullpen(obj: WaitingApprovalJuiceBullpen) {
        try {
            obj.setDeleting(true);
            await this.apiService.post(ApiRoutes.approveJuiceBullpens.reject(obj.id));
            obj.setDeleting(false);
            obj.delete();
        } catch (e) {
            obj.setDeleting(false);
            throw e;
        }
    }

    @action
    async approveBullpensPolling() {
        let createdAt;
        const bullpens = this.bullpensList.items;

        if (bullpens.length > 0) {
            createdAt = bullpens[0].created_at;
            bullpens.forEach(bullpen => {
                if (bullpen.created_at > createdAt) {
                    createdAt = bullpen.created_at;
                }
            });
        } else {
            createdAt = moment().subtract(1, "minutes").toDate();
        }
        const response = await this.apiService.get(
            ApiRoutes.approveJuiceBullpens.polling,
            {
                after: createdAt,
            },
        );
        const list = response.data.map(
            data =>
                WaitingApprovalJuiceBullpen.fromJson(data) as WaitingApprovalJuiceBullpen,
        );
        this.bullpensList.appendItems(list);
    }
}
