import NewModel from "./NewModel";
import { observable } from "mobx";
import { User } from "./UserModel";
import { Specification } from "./Specification";
import { Bullpen } from "./Bullpen";
import { BullpenGroupStatusTypes } from "enums/BullpenGroupStatusTypes";
import { TagOwner } from "./TagOwner";

export class BullpenGroup extends NewModel {
  @observable id!: number;

  @observable scale!: string;

  @observable out_time!: string;

  @observable in_time!: string;

  @observable condition_notes!: string;

  @observable thermometer_count!: number;

  @observable scale_check!: string;

  @observable created_at!: Date;

  @observable updated_at!: Date;

  @observable creator!: User;

  @observable specifications!: Specification;

  @observable bullpens!: Bullpen[];

  @observable status: BullpenGroupStatusTypes;

  @observable tripoly_exists?: boolean;

  @observable date?: Date;

  @observable tag_owner_id?: number;

  @observable tagOwner?: TagOwner;

}
