import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Integration } from "@sentry/types";
import { MsalProvider } from "@azure/msal-react";
import { Configuration,  LogLevel,  PublicClientApplication } from "@azure/msal-browser";

const configuration: Configuration = {
  auth: {
      clientId: "cb117f94-454d-4773-b743-75e5b8d2e044",
      authority: "https://login.microsoftonline.com/b2629019-653c-4916-8b29-d4db7807a96c",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {	
      loggerOptions: {	
          loggerCallback: (level, message, containsPii) => {	
              if (containsPii) {		
                  return;		
              }		
              switch (level) {		
                  case LogLevel.Error:		
                      console.error(message);		
                      return;		
                  case LogLevel.Info:		
                      console.info(message);		
                      return;		
                  case LogLevel.Verbose:		
                      console.debug(message);		
                      return;		
                  case LogLevel.Warning:		
                      console.warn(message);		
                      return;		
              }	
          }	
      }	
  }
};


export const loginRequest = {
  scopes: ["https://analysis.windows.net/powerbi/api/dataset.readwrite.all"],
};


const pca = new PublicClientApplication(configuration);

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    debug: true,
    release: process.env.REACT_APP_VERSION,
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing() as Integration],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
  <MsalProvider instance={pca}>
    <App />
   </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
