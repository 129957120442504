import React from "react";
import { ColumnContainer } from "components/globals";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

interface Props {}

const MainContainer = styled(ColumnContainer)`
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
`;

const Loader: React.FC<Props> = (props: Props) => (
  <MainContainer>
    <CircularProgress size={40} thickness={4} color="secondary" />
  </MainContainer>
);

export default Loader;
