import React from "react";
import { Redirect, Route } from "react-router";
import { getCompleteRoute, RouteComponents } from "utils/routing";
import { authService } from "services/AuthService";

const ProtectedRoute: React.FC<any> = ({ component: Component, ...rest }) => {
  if (!authService.hasAuthToken) {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              pathname: getCompleteRoute(RouteComponents.LOGIN),
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }
  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default ProtectedRoute;
