import { observable } from "mobx";
import { SpecificationStore } from "stores/SpecificationStore";
import { Specie } from "./Specie";
import { Customer } from "./Customer";
import { CutSize } from "./CutSize";
import { SpecificationHistory } from "./SpecificationHistory";
import NewModel from "./NewModel";

export class Specification extends NewModel {
  static _store: SpecificationStore;

  @observable id!: number;

  @observable specification_number!: string;

  @observable customer_item_number!: number;

  @observable is_archived!: boolean;

  @observable specie!: Specie;

  @observable customer!: Customer;

  @observable cutSize!: CutSize;

  @observable specificationsData!: SpecificationHistory[];

  @observable currentData!: SpecificationHistory;
}
