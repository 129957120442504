import { action, computed, observable } from "mobx";
import { Tag } from "models/Tag";
import ApiRoutes from "routes/ApiRoutes";
import { ModelItem } from "models/ModelItem";
import NewStore from "./NewStore";
import { PaginatedModelList } from "models/PaginatedModelList";

export class TagStore extends NewStore<Tag> {
  private static _instance: TagStore;

  constructor() {
    super();
    Tag._store = this;
  }

  static getInstance(): TagStore {
    if (!this._instance) {
      this._instance = new TagStore();
    }
    return this._instance;
  }

  @observable tagList = new PaginatedModelList<Tag>(Tag);

  @observable tagItem = new ModelItem<Tag>(Tag);

  @computed get tags() {
    return this.tagList.items.sort(
      (a, b) => a.starting_tag_no - b.starting_tag_no,
    );
  }

  @computed get activeTags() {
    return this.tags.filter(e => !e.is_archived);
  }

  @computed get inActiveTags() {
    return this.tags.filter(e => e.is_archived);
  }

  @action
  async fetchTags(params?: { [key: string]: any }) {
    return this.tagList.load(ApiRoutes.tags.list, params);
  }

  @action
  async createTags(body: { [key: string]: any }) {
    const response = await this.apiService.post(ApiRoutes.tags.list, body);
    const tag = Tag.fromJson(response.data) as Tag;
    this.tagList.appendItem(tag);
    return tag;
  }

  @action
  async updateElement(tag: Tag, body: { [key: string]: any }) {
    try {
      tag.setUpdating(true);
      const response = await this.apiService.put(
        ApiRoutes.tags.show(tag.id),
        body,
      );
      tag.setUpdating(false);
      tag.updateFromJson(response.data);
      return tag;
    } catch (e) {
      tag.setUpdating(false);
      throw e;
    }
  }

  @action
  async deleteElement(tag: Tag) {
    try {
      tag.setDeleting(true);
      await this.apiService.delete(ApiRoutes.tags.show(tag.id));
      tag.setDeleting(false);
      tag.delete();
    } catch (e) {
      tag.setDeleting(false);
      throw e;
    }
  }
}
