import { CoolerStore } from "stores/CoolerStore";
import { ManageElement } from "./ManageElement";
import { observable } from "mobx";
import { Customer } from "./Customer";

export class Cooler extends ManageElement {
  static _store: CoolerStore;

  @observable customer_id!: number;

  @observable customer!: Customer;
}
