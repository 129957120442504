import { ShiftStore } from "stores/ShiftStore";
import { observable } from "mobx";
import { ShiftBreak } from "models/ShiftBreak";
import NewModel from "./NewModel";

export class Shift extends NewModel {
  static _store: ShiftStore;

  @observable id!: number;

  @observable dumper_id!: number;

  @observable shift_type_id!: number;

  @observable start_time!: Date;

  @observable end_time!: Date;

  @observable total_time!: string;

  @observable created_at!: Date;

  @observable updated_at!: Date;

  @observable shiftBreak!: Array<ShiftBreak>;
}
