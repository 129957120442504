import { DesignationStore } from "stores/DesignationStore";
import { observable } from "mobx";
import { PermissionArea, PermissionLevel } from "enums/permission.enum";
import NewModel from "./NewModel";

export class Designation extends NewModel {
  static _store: DesignationStore;

  @observable id!: number;

  @observable name!: string;

  @observable created_at!: Date;

  @observable updated_at!: Date;

  @observable permissions!: { [key in PermissionArea]: PermissionLevel };
}
