import { action, observable, ObservableMap } from "mobx";
import { NewApiService } from "services/NewApiService";
import NewModel from "models/NewModel";

export type EntityIdentifier = string | number;

export abstract class NewStore<M extends NewModel> {
  protected apiService = NewApiService.getInstance();

  public entities = new ObservableMap<EntityIdentifier, M>();

  @observable searchFilterParam: { [key: string]: any } = {};

  public get(id: EntityIdentifier): M {
    return this.entities.get(id);
  }

  @action
  public push(entity: M) {
    this.entities.set(entity.id, entity);
  }
}

export default NewStore;
