import Axios, {
  AxiosRequestConfig,
  CancelToken,
  CancelTokenSource,
} from "axios";
import URI from "urijs";
import { ErrorCode, SurfSideError } from "models/SurfSideError";
import { Utils } from "utils/utils";
import * as Sentry from "@sentry/react";

interface RequestConfig extends AxiosRequestConfig {
  requestId?: string;
  redirectIfUnauthorized?: boolean;
}

export class NewApiService {
  private static instance = new NewApiService();

  private requestMap = new Map<string, CancelTokenSource>();

  private unreportedErrors = [
    ErrorCode.UNAUTHORIZED,
    ErrorCode.NOT_FOUND,
    ErrorCode.CONFLICT_REQUEST,
    ErrorCode.REQUEST_TIMEOUT,
  ];

  public static getInstance() {
    return this.instance;
  }

  public get(url: string, params?: any, headers?: any, requestId?: string) {
    return this.request({ method: "GET", url, headers, params, requestId });
  }

  public delete(url: string, params?: any, headers?: any, requestId?: string) {
    return this.request({ method: "DELETE", url, headers, params, requestId });
  }

  public post(
    url: string,
    data?: any,
    headers?: any,
    params?: any,
    requestId?: string,
  ) {
    return this.request({
      method: "POST",
      url,
      data,
      headers,
      params,
      requestId,
    });
  }

  public put(
    url: string,
    data?: any,
    headers?: any,
    params?: any,
    requestId?: string,
  ) {
    return this.request({
      method: "PUT",
      url,
      data,
      headers,
      params,
      requestId,
    });
  }

  public patch(
    url: string,
    data?: any,
    headers?: any,
    params?: any,
    requestId?: string,
  ) {
    return this.request({
      method: "PATCH",
      url,
      data,
      headers,
      params,
      requestId,
    });
  }

  generateHeaders = headers => {
    const defaultHeaders = {
      Authorization: localStorage.getItem("auth_token"),
    };
    if (!headers) {
      return defaultHeaders;
    }
    return { ...defaultHeaders, ...headers };
  };

  // TODO: pass token only when required.
  private async request(config: RequestConfig) {
    const cancelToken = this.addToRequestMap(config.requestId);
    try {
      const response = await Axios.request({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        cancelToken,
        ...config,
        headers: this.generateHeaders(config.headers),
        timeout: 30 * 1000,
      });
      this.removeFromRequestMap(config.requestId);
      return response?.data;
    } catch (error) {
      const errorStatus = error?.response?.status;
      if (errorStatus === ErrorCode.UNAUTHORIZED) {
        window.location.href = URI("/auth/login").query({
          redirect_to: URI().pathname(),
        });
      }
      if (this.shouldReportToSentry(errorStatus)) {
        // Report To sentry or any other service.
        if (!config.url.includes("polling")) {
          let body: { [key: string]: any } = {};
          body.userId = localStorage.getItem("user_id");
          const map = error?.response || SurfSideError.from(error, config.url);
          body = { ...body, ...map };
          Sentry.captureException(body);
        }
      }

      throw SurfSideError.from(error, config.url);
    }
  }

  private shouldReportToSentry(errorStatus: ErrorCode) {
    return !this.unreportedErrors.includes(errorStatus);
  }

  private addToRequestMap(requestId?: string): CancelToken {
    if (!requestId) {
      return undefined;
    }

    const source = Axios.CancelToken.source();
    this.requestMap.set(requestId, source);
    return source.token;
  }

  private removeFromRequestMap(requestId?: string) {
    if (!requestId) {
      return;
    }

    this.requestMap.delete(requestId);
  }

  public generateRequsetId(): string {
    return Utils.getRandomString();
  }

  public cancelRequest(requestId: string) {
    const source = this.requestMap.get(requestId);
    source && source.cancel();
  }
}
