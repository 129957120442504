import { theme } from "./colors";
import { createMuiTheme } from "@material-ui/core";

export type Theme = typeof theme;

export const materialTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: theme.material.primary,
    secondary: theme.material.secondary,
    warning: theme.material.warning,
  },
  typography: {
    fontFamily: "niveau-grotesk, sans-serif",
    fontWeightBold: 700,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "antialiased",
        },
      },
    },
    MuiInput: {
      input: {
        fontSize: "1.125rem",
        fontWeight: "bold",
        width: "100%",
        "&::placeholder": {
          fontWeight: "normal",
          color: "#7E7E7E",
          opacity: "1.0",
        },
      },
    },
    MuiSelect: {
      select: {
        width: "100%",
        fontSize: "1.125rem",
        fontWeight: "bold",
      },
    },
    MuiMenuItem: {
      root: {
        "&$selected": {
          backgroundColor: "rgb(20,155,165)",
          color: "#FFFFFF",
        },
      },
    },
  },
});
