import { action, observable } from "mobx";
import NewStore, { EntityIdentifier } from "stores/NewStore";

export interface ModelJson {
  id: number;

  [key: string]: any;
}

export abstract class NewModel {
  protected omittedKeys: string[] = [];

  @observable deleting: boolean = false;

  @observable deleted: boolean = false;

  @observable updating: boolean = false;

  @observable updated: boolean = false;

  @observable selected: boolean = false;

  @action delete() {
    this.deleted = true;
  }

  @action setIsSelected(selected) {
    this.selected = selected;
  }

  @action
  public setDeleting(value) {
    this.deleting = value;
  }

  @action
  public setUpdating(value) {
    this.updating = value;
  }

  toggleSelected() {
    this.setIsSelected(!this.selected);
  }

  constructor(readonly id: EntityIdentifier) {}

  static getStore(): NewStore<NewModel> {
    const store = (this as any)._store;
    if (!store) {
      console.error(`_store not defined in ${this}
            Please define _store and assign 'this' to it in parent store's constructor`);
    }
    return store;
  }

  static fromJson(
    json: ModelJson,
    identifierKey: string = "id",
  ): NewModel | null {
    if (!json) {
      return null;
    }
    const id = json[identifierKey] as EntityIdentifier;
    const entity = this.getOrNew(id);
    entity.updateFromJson(json);
    return entity;
  }

  public static getOrNew(id: EntityIdentifier): NewModel {
    let entity = this.getStore().get(id);

    if (!entity) {
      entity = new (this as any)(id);
      this.getStore().push(entity!);
    }

    return entity!;
  }

  public static get(id: EntityIdentifier) {
    return this.getStore().get(id);
  }

  @action
  updateFromJson(json: ModelJson) {
    for (const k in json) {
      if (!json.hasOwnProperty(k)) {
        continue;
      }
      if (this.omittedKeys && this.omittedKeys.indexOf(k) !== -1) {
        continue;
      }
      const deserializer = this.getDeserializer(k);
      if (deserializer) {
        json[k] && deserializer.bind(this)(json[k]);
      } else if (this[k] && this[k].deserialize) {
        this[k].deserialize(json[k]);
      } else {
        this[k] = json[k];
      }
    }
  }

  private getDeserializer(prop: string) {
    const _methodName = `deserialize_${prop}`;
    return this[_methodName];
  }
}

export default NewModel;
