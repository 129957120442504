import { action, computed, observable } from "mobx";
import { BullpenWeight } from "models/BullpenWeight";
import NewStore from "./NewStore";
import { ModelItem } from "models/ModelItem";
import { ModelList } from "models/ModelList";
import ApiRoutes from "routes/ApiRoutes";

export class BullpenWeightStore extends NewStore<BullpenWeight> {
  private static _instance: BullpenWeightStore;

  constructor() {
    super();
    BullpenWeight._store = this;
  }

  @observable bullpenWeightsItem = new ModelItem<BullpenWeight>(BullpenWeight);

  @observable bullpenWeightList = new ModelList<BullpenWeight>(BullpenWeight);

  static getInstance(): BullpenWeightStore {
    if (!this._instance) {
      this._instance = new BullpenWeightStore();
    }
    return this._instance;
  }

  @computed get bullpenWeights() {
    return this.bullpenWeightList.items.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );
  }

  @computed get activeBullpenWeights() {
    return this.bullpenWeights.filter(e => !e.is_archived);
  }

  @computed get inActiveBullpenWeights() {
    return this.bullpenWeights.filter(e => e.is_archived);
  }

  @action
  async fetchBullpenWeights(params?: { [key: string]: any }) {
    return this.bullpenWeightList.load(ApiRoutes.bullpenWeights.list, params);
  }

  @action
  async createElement(name: string) {
    const response = await this.apiService.post(ApiRoutes.bullpenWeights.list, {
      name,
    });
    const bullpenWeight = BullpenWeight.fromJson(
      response.data,
    ) as BullpenWeight;
    this.bullpenWeightList.appendItem(bullpenWeight);
    return bullpenWeight;
  }

  @action
  async updateElement(
    bullpenWeight: BullpenWeight,
    body: { [key: string]: any },
  ) {
    try {
      bullpenWeight.setUpdating(true);
      const response = await this.apiService.put(
        ApiRoutes.bullpenWeights.show(bullpenWeight.id),
        body,
      );
      bullpenWeight.setUpdating(false);
      bullpenWeight.updateFromJson(response.data);
      return bullpenWeight;
    } catch (e) {
      bullpenWeight.setUpdating(false);
      throw e;
    }
  }

  @action
  async deleteElement(bullpenSize: BullpenWeight) {
    try {
      bullpenSize.setDeleting(true);
      await this.apiService.delete(
        ApiRoutes.bullpenWeights.show(bullpenSize.id),
      );
      bullpenSize.setDeleting(false);
      bullpenSize.delete();
    } catch (e) {
      bullpenSize.setDeleting(false);
      throw e;
    }
  }

  @action
  async fetchBullpenWeightFromId(id: number) {
    return this.bullpenWeightList.load(ApiRoutes.bullpenWeights.show(id));
  }
}
