import { action, observable } from "mobx";
import { ModelItem } from "models/ModelItem";
import { ModelList } from "models/ModelList";
import { SOPForm } from "models/sop/SOPForm";
import { SOPQuestion } from "models/sop/SOPQuestion";
import ApiRoutes from "routes/ApiRoutes";
import NewStore from "./NewStore";

export class SOPFormStore extends NewStore<SOPForm> {
  private static _instance: SOPFormStore;

  @observable sopFormsList = new ModelList<SOPForm>(SOPForm);

  @observable sopFormCreated = new ModelItem<SOPForm>(SOPForm);

  @observable questions: SOPQuestion[] = [];

  @observable isFetchingQuestions: boolean = false;

  constructor() {
    super();
    SOPForm._store = this;
  }

  static getInstance(): SOPFormStore {
    if (!this._instance) {
      this._instance = new SOPFormStore();
    }
    return this._instance;
  }

  @action
  async fetchForms(params?: { [key: string]: any }) {
    return this.sopFormsList.load(ApiRoutes.forms.getFormsByDate, params, {
      dataKey: "sop_forms",
    });
  }

  @action
  async fetchQuestions() {
    if (this.questions.length <= 0) {
      try {
        this.isFetchingQuestions = true;
        const response = await this.apiService.get(
          ApiRoutes.forms.sop.listQuestions,
        );
        this.questions.push(...response.data);
      } finally {
        this.isFetchingQuestions = false;
      }
    }
  }

  @action
  async createForm(body: { [key: string]: any }) {
    const response = await this.apiService.post(
      ApiRoutes.forms.sop.createForm,
      body,
    );
    const form = SOPForm.fromJson(response.sop_form) as SOPForm;
    this.sopFormCreated.setItem(form);
    this.sopFormsList.appendItem(form);
  }

  @action
  async createEntry(body: { [key: string]: any }) {
    const response = await this.apiService.post(
      ApiRoutes.forms.sop.createEntries(this.sopFormCreated.item.id),
      body,
    );
    this.sopFormCreated.item.sopFormEntries.push(response.sop_form_entry);
  }

  @action
  async createFirstEntry(body: { [key: string]: any }) {
    await this.createEntry(body);
    this.sopFormCreated.setItem(null);
  }

  @action
  async createSuccessiveEntry(sopId: number, body: { [key: string]: any }) {
    const sop = this.sopFormsList.items.find(el => el.id === sopId);
    this.sopFormCreated.setItem(sop);
    await this.createEntry(body);
    this.sopFormCreated.setItem(null);
  }

  @action
  async updateForms(sop: SOPForm, body: { [key: string]: any }) {
    sop.setUpdating(true);
    const response = await this.apiService.put(
      ApiRoutes.forms.sop.updateForm(sop.id),
      body,
    );
    sop.updateFromJson(response.sop_form);
    sop.setUpdating(false);
  }
}
