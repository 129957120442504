import { observable } from "mobx";
import { SOPFormStore } from "stores/SOPFormStore";
import NewModel from "../NewModel";
import { SOPQuestion } from "./SOPQuestion";

export class SOPForm extends NewModel {
  static _store: SOPFormStore;

  @observable id!: number;

  @observable area: string;

  @observable notes!: string;

  @observable date!: string;

  @observable created_at!: Date;

  @observable updated_at!: Date;

  @observable sopFormEntries: Entries[];
}

export interface Entries {
  id: number;
  sop_form_id: number;
  created_at: Date;
  updated_at: Date;
  sopFormEntryAnswers: EntryAnswers[];
}

export interface EntryAnswers {
  id: number;
  sop_form_id: number;
  sop_form_question_id: number;
  answer: boolean;
  observation: string;
  created_at: Date;
  updated_at: Date;
  sopFormQuestion: SOPQuestion;
}
