import NewModel from "./NewModel";
import { observable } from "mobx";
import { GoalsStore } from "stores/GoalsStore";
import { GoalBoat } from "./GoalBoat";
import { GoalCustomer } from "./GoalCustomer";

interface DumpingGoals {
  expected_dumping: number;
}

export class Goals extends NewModel {
  static _store: GoalsStore;

  @observable boats!: GoalBoat[];
  
  @observable customers!: GoalCustomer[];

  @observable date!: string;

  @observable dumping_goal: DumpingGoals;

  @observable dumped_count: number;
}
