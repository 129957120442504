import NewStore from "./NewStore";
import { action, computed, observable } from "mobx";
import ApiRoutes from "routes/ApiRoutes";
import { ModelItem } from "models/ModelItem";
import { PaginatedModelList } from "models/PaginatedModelList";
import { format } from "date-fns";
import { ModelList } from "models/ModelList";
import { Dictionary } from "services/ApiService";
import { JuiceTubSheet } from "./JuiceTubSheet";

export class JuiceBullpenStore extends NewStore<JuiceTubSheet> {
  private static _instance: JuiceBullpenStore;

  @observable juiceBullpensList = new PaginatedModelList<JuiceTubSheet>(JuiceTubSheet);

  @observable juiceBullpenItem = new ModelItem<JuiceTubSheet>(JuiceTubSheet);

  @observable selectedJuiceBullpenForAdd = new ModelItem<JuiceTubSheet>(JuiceTubSheet);

  @observable isPreviousFetching: boolean = false;

  @observable detailPageId: number = 0;

  @observable onGoingJuiceBullpenGroups = new ModelList<JuiceTubSheet>(JuiceTubSheet);

  constructor() {
    super();
    JuiceTubSheet._store = this;
    this.searchFilterParam.date = format(new Date(), "yyyy-MM-dd");
  }

  static getInstance(): JuiceBullpenStore {
    if (!this._instance) {
      this._instance = new JuiceBullpenStore();
    }
    return this._instance;
  }

  @computed get juiceBullpens() {
    return this.juiceBullpensList.items;
  }

  @action
  async fetchJuiceBullpens(params?: { [key: string]: any }) {
    return this.juiceBullpensList.load(ApiRoutes.juiceTubSheet.fetch, params);
  }

  @action
  async fetchJuiceBullpenById(id: number) {
    return this.juiceBullpenItem.load(ApiRoutes.juiceTubSheet.show(id));
  }

  @action
  async fetchPreviousJuiceBullpen(id: number) {
    try {
      this.isPreviousFetching = true;
      const juiceBullpenGroupExists = this.onGoingJuiceBullpenGroups.items.find(
        el => el.id === id,
      );
      if (!!juiceBullpenGroupExists) {
        this.selectedJuiceBullpenForAdd.setItem(juiceBullpenGroupExists);
        this.selectedJuiceBullpenForAdd.loaded = true;
      } else {
        await this.selectedJuiceBullpenForAdd.load(ApiRoutes.juiceTubSheet.show(id));
      }
    } finally {
      this.isPreviousFetching = false;
    }
  }


  @action
  async fetchSearchedQuery(query?: { [key: string]: any }) {
    this.searchFilterParam = { ...this.searchFilterParam, ...query };
    await this.fetchJuiceBullpens(this.searchFilterParam);
    if (this.searchFilterParam.page) {
      delete this.searchFilterParam.page;
    }
  }


  @action
  async fetchOnGoingJuiceBullpenGroups(params?: Dictionary<any>) {
    return this.onGoingJuiceBullpenGroups.load(
      ApiRoutes.juiceInspections.ongoing,
      params,
      {
        forceRefresh: true,
      },
    );
  }

  @action
  setDetailPageId(id: number) {
    this.detailPageId = id;
  }

  @action
  getDetailPageId() {
    return this.detailPageId;
  }
}
