import { observable } from "mobx";
import { Tag } from "models/Tag";
import { Boat } from "models/Boat";
import { User } from "models/UserModel";
import { DumpingStore } from "stores/DumpingStore";
import { Shift } from "models/Shift";
import NewModel from "./NewModel";
import { Specie } from "./Specie";
import { TagOwner } from "./TagOwner";

export class Dumping extends NewModel {
  static _store: DumpingStore;

  @observable id!: number;

  @observable tag_id!: number;

  @observable tag_number!: number;

  @observable boat_id!: number;

  @observable specie_id!: number;

  @observable dumper_id!: number;

  @observable shift_id!: number;

  @observable cage_low_level!: number;

  @observable dump_time!: Date;

  @observable created_at!: Date;

  @observable updated_at!: Date;

  @observable tag!: Tag;

  @observable boat!: Boat;

  @observable specie!: Specie;

  @observable user!: User;

  @observable shift!: Shift;

  @observable tagOwner!: TagOwner;
}
