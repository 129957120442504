import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import { GlobalProps } from "App";
import { withTheme } from "styled-components";
import { FullPageContainer } from "components/globals";
import { DesignationStore } from "stores/DesignationStore";
import { ShiftStore } from "stores/ShiftStore";
import { PermissionStore } from "stores/PermissionStore";
import { UserStore } from "stores/UserStore";
import { authService } from "services/AuthService";
import {
  STORE_DESIGNATION,
  STORE_PERMISSION,
  STORE_SHIFT,
  STORE_USER,
} from "constants/stores";
import Loader from "components/shared/loader/Loader";
import { toast } from "react-toastify";

interface SplashScreenProps extends GlobalProps {
  [STORE_DESIGNATION]?: DesignationStore;
  [STORE_SHIFT]?: ShiftStore;
  [STORE_PERMISSION]?: PermissionStore;
  [STORE_USER]?: UserStore;
  children?: React.ReactElement;
}

const SplashScreen: React.FC<SplashScreenProps> = (
  props: SplashScreenProps,
) => {
  const {
    designationStore,
    permissionStore,
    shiftStore,
    userStore,
    children,
  } = props;
  const { currentShiftItem } = shiftStore!;

  useEffect(() => {
    const fetchPermission = async () => {
      if (
        permissionStore!.permissionList.length < 1 &&
        !permissionStore!.isLoading
      ) {
        await permissionStore!.fetchPermissions();
      } else {
        return Promise.resolve();
      }
    };

    const checkTokenExpiry = async () => {
      if (authService.hasAuthToken) {
        try {
          await userStore!.getMe();
          await Promise.all([
            designationStore!.fetchDesignations(),
            shiftStore!.fetchCurrentShift(),
            shiftStore!.fetchShiftType(),
            fetchPermission(),
          ]);
        } catch (e) {
          toast.error(e.message);
        }
      }
    };

    checkTokenExpiry();
  }, []);

  const loading = () =>
    userStore!.loggedInUserItem.loading ||
    designationStore!.designations.loading ||
    permissionStore!.isLoading ||
    currentShiftItem.loading ||
    shiftStore!.isShiftTypeListFetching;

  if (loading()) {
    return (
      <FullPageContainer>
        <Loader />
      </FullPageContainer>
    );
  }
  return <>{children}</>;
};

export default inject(
  STORE_DESIGNATION,
  STORE_SHIFT,
  STORE_PERMISSION,
  STORE_USER,
)(withTheme(observer(SplashScreen)));
