import NewStore from "./NewStore";
import { DownTime } from "models/DownTime";
import { action, observable } from "mobx";
import { ModelList } from "models/ModelList";
import ApiRoutes from "routes/ApiRoutes";

export class DownTimeStore extends NewStore<DownTime> {
  private static _instance: DownTimeStore;

  @observable downtimeTypeList = new ModelList<DownTime>(DownTime);

  constructor() {
    super();
    DownTime._store = this;
  }

  static getInstance(): DownTimeStore {
    if (!this._instance) {
      this._instance = new DownTimeStore();
    }
    return this._instance;
  }

  @action
  async fetchDownTimeTypes(params?: { [key: string]: any }) {
    return this.downtimeTypeList.load(ApiRoutes.downtimeTypes.list, params);
  }
}
