import { observable } from "mobx";
import { Boat } from "./Boat";
import { Specie } from "./Specie";
import { User } from "./UserModel";
import { Cooler } from "./Cooler";
import { TrailerCondition } from "./TrailerCondition";
import { ShellStockStore } from "stores/ShellStockStore";
import { HarvestLocation } from "./HarvestLocation";
import NewModel from "./NewModel";

export class ShellStock extends NewModel {
  static _store: ShellStockStore;

  @observable id!: number;

  @observable load_number!: number;

  @observable bill_of_landing!: string;

  @observable actual_temperature!: string;

  @observable harvest_date!: string;

  @observable rec_log!: string;

  @observable cages_received!: number;

  @observable cages_returned!: number;

  @observable seal_number!: string;

  @observable refer_number!: string;

  @observable refer_code!: string;

  @observable catch_date!: string;

  @observable received_date!: string;

  @observable received_time!: string;

  @observable dock_location!: string;

  @observable boat!: Boat;

  @observable specie!: Specie;

  @observable creator!: User;

  @observable trailerCondition!: TrailerCondition;

  @observable cooler!: Cooler;

  @observable harvestLocation!: HarvestLocation;

  @observable beginning_tag_run?: string;

  @observable ending_tag_run?: string;
}
