import { action, computed, observable } from "mobx";
import { ModelList } from "models/ModelList";
import NewStore from "./NewStore";
import ApiRoutes from "routes/ApiRoutes";
import { ModelItem } from "models/ModelItem";
import { TagOwner } from "models/TagOwner";

export class TagOwnerStore extends NewStore<TagOwner> {
    private static _instance: TagOwnerStore;

    @observable tagOwnerslist = new ModelList<TagOwner>(TagOwner);

    @observable tagOwnerItem = new ModelItem<TagOwner>(TagOwner);

    constructor() {
        super();
        TagOwner._store = this;
    }

    static getInstance(): TagOwnerStore {
        if (!this._instance) {
            this._instance = new TagOwnerStore();
        }
        return this._instance;
    }

    @computed get tagOwners() {
        return this.tagOwnerslist.items.sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        );
    }

    @computed get tagOwner() {
        return this.tagOwnerItem.item;
    }

    @computed get activeTagOwners() {
        return this.tagOwners.filter(e => !e.is_archived);
    }

    @computed get inActiveTagOwners() {
        return this.tagOwners.filter(e => e.is_archived);
    }

    @action
    async fetchTagOwnerFromId(id: number) {
        return this.tagOwnerItem.load(ApiRoutes.tagOwners.show(id));
    }

    @action
    async fetchTagOwners(params?: { [key: string]: any }) {
        return this.tagOwnerslist.load(ApiRoutes.tagOwners.list, params);
    }

    @action
    async createElement(name: string) {
        const response = await this.apiService.post(ApiRoutes.tagOwners.list, { name });
        const tagOwner = TagOwner.fromJson(response.data) as TagOwner;
        this.tagOwnerslist.appendItem(tagOwner);
        return tagOwner;
    }

    @action
    async updateElement(tagOwner: TagOwner, body: { [key: string]: any }) {
        try {
            tagOwner.setUpdating(true);
            const response = await this.apiService.put(
                ApiRoutes.tagOwners.show(tagOwner.id),
                body,
            );
            tagOwner.setUpdating(false);
            tagOwner.updateFromJson(response.data);
            return tagOwner;
        } catch (e) {
            tagOwner.setUpdating(false);
            throw e;
        }
    }

    @action
    async deleteElement(tagOwner: TagOwner) {
        try {
            tagOwner.setDeleting(true);
            await this.apiService.delete(ApiRoutes.tagOwners.show(tagOwner.id));
            tagOwner.setDeleting(false);
            tagOwner.delete();
        } catch (e) {
            tagOwner.setDeleting(false);
            throw e;
        }
    }
}
