import NewModel from "../NewModel";
import { observable } from "mobx";
import { DistributionStore } from "stores/DistributionStore";
import { BullpenDistribution } from "./BullpenDistribution";

export class Distribution extends NewModel {
  static _store: DistributionStore;

  @observable id!: number;

  @observable bill_of_landing!: string;

  @observable truck_number!: string;

  @observable forklift_driver!: string;

  @observable truck_driver!: string;

  @observable trailer_temp!: string;

  @observable temp_checker!: string;

  @observable pre_shipment_review!: string;

  @observable supervisor_id!: number;

  @observable trailer_clean!: boolean;

  @observable odor!: boolean;

  @observable chemicals: boolean;

  @observable hazardous_chemicals!: boolean;

  @observable any_visible_damage!: boolean;

  @observable insects_or_rodents!: boolean;

  @observable trailer_precooled!: boolean;

  @observable product_temperature!: string;

  @observable trailer_set_point!: string;

  @observable pallets_in!: string;

  @observable pallets_out!: string;

  @observable pieces_count!: number;

  @observable created_at!: Date;

  @observable updated_at!: Date;

  @observable bullpenDistributions!: BullpenDistribution[];

  @observable lidsCount!: number;
}
