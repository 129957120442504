import { ShiftType } from "models/ShiftType";
import { Shift } from "models/Shift";
import { action, computed, observable } from "mobx";
import { ShiftBreak } from "models/ShiftBreak";
import { ShiftBreakType } from "enums/ShiftBreakType";
import NewStore from "./NewStore";
import { ModelItem } from "models/ModelItem";
import ApiRoutes from "routes/ApiRoutes";
import { Dictionary } from "services/ApiService";
import { format } from "date-fns";

export class ShiftStore extends NewStore<Shift> {
  @observable isShiftTypeListFetching: boolean = false;

  @observable isTakingBreak: boolean = false;

  @observable public currentShiftItem = new ModelItem<Shift>(Shift);

  @observable public previousShiftItem = new ModelItem<Shift>(Shift);

  @observable public shiftByDateItem = new ModelItem<Shift>(Shift);

  @observable endingShift: boolean = false;

  @observable resumingShift: boolean = false;

  @observable shiftTypesList: Array<ShiftType> = [];

  constructor() {
    super();
    Shift._store = this;
  }

  @computed get currentShiftTypeName() {
    return this.shiftTypesList.find(
      s => s.id === this.currentShiftItem!.item.shift_type_id,
    )?.name;
  }

  @computed get hasActiveShiftBreaks() {
    return this.currentShiftItem.item?.shiftBreak.filter(
      s => s.end_time === null,
    )[0];
  }

  @computed get hasActiveLunchShiftBreak() {
    return this.currentShiftItem.item?.shiftBreak.filter(
      s => s.end_time === null && s.type === ShiftBreakType.LUNCH_TIME,
    )[0];
  }

  @computed get hasActiveDownTimeShiftBreak() {
    return this.currentShiftItem.item?.shiftBreak.filter(
      s => s.end_time === null && s.type === ShiftBreakType.DOWN_TIME,
    )[0];
  }

  @computed get currentShift() {
    return this.currentShiftItem.item;
  }

  @action
  async fetchCurrentShift() {
    try {
      await this.currentShiftItem.load(ApiRoutes.currentShift.fetch);
      if (!this.currentShiftItem.item) {
        try {
          this.previousShiftItem.load(ApiRoutes.shiftByDate.dumping, {
            date: format(new Date(), "yyyy-MM-dd"),
          });
        } catch (e) {
          this.previousShiftItem.setItem(null);
          throw e;
        }
      }
    } catch (e) {
      throw e;
    }
  }

  @action
  async fetchShiftByDate(params?: Dictionary<any>) {
    try {
      return await this.shiftByDateItem.load(
        ApiRoutes.shiftByDate.list,
        params,
      );
    } catch (e) {
      this.shiftByDateItem.setLoaded(false);
      this.shiftByDateItem.setItem(null);
      throw e;
    }
  }

  @action
  async createCurrentShift(body: { [key: string]: any }) {
    const response = await this.apiService.post(
      ApiRoutes.currentShift.create,
      body,
    );
    const shift: Shift = Shift.fromJson(response.data) as Shift;
    shift.shiftBreak = [];
    this.currentShiftItem.setItem(shift);
    this.previousShiftItem.setItem(null);
    return shift;
  }

  @action
  async endCurrentShift() {
    try {
      this.endingShift = true;
      const response = await this.apiService.put(ApiRoutes.currentShift.end);
      this.previousShiftItem.setItem(response.data);
      this.currentShiftItem.setItem(null);
      this.endingShift = false;
    } catch (e) {
      this.endingShift = false;
      throw e;
    }
  }

  @action
  async resumeCurrentShift() {
    try {
      this.resumingShift = true;
      const response = await this.apiService.put(
        ApiRoutes.currentShift.resume(this.previousShiftItem.item.id),
      );
      this.currentShiftItem.setItem(response.data);
      this.previousShiftItem.setItem(null);
      this.resumingShift = false;
    } catch (e) {
      this.resumingShift = false;
      throw e;
    }
  }

  // Shift Type
  @action
  async fetchShiftType(body?: { [key: string]: any }) {
    try {
      if (this.shiftTypesList.length > 1) {
        return;
      }
      this.isShiftTypeListFetching = true;
      const shifts = await this.apiService.get(ApiRoutes.shiftType.fetch, body);
      this.shiftTypesList.push(...shifts.data);
      this.isShiftTypeListFetching = false;
    } catch (e) {
      this.isShiftTypeListFetching = false;
      throw e;
    }
  }

  // Shift Break
  @action
  async createShiftBreak(body: { [key: string]: any }) {
    try {
      this.isTakingBreak = true;
      const response = await this.apiService.post(
        ApiRoutes.shiftBreak.start,
        body,
      );
      const shiftBreak: ShiftBreak = response.data;
      shiftBreak.end_time = null;
      this.currentShiftItem!.item.shiftBreak.push(shiftBreak);
      this.isTakingBreak = false;
    } catch (e) {
      this.isTakingBreak = false;
      throw e;
    }
  }

  @action
  async endShiftBreak() {
    try {
      this.isTakingBreak = true;
      const response = await this.apiService.put(ApiRoutes.shiftBreak.end);
      const shiftBreak: ShiftBreak = response.data;
      const index = this.currentShiftItem?.item.shiftBreak.findIndex(
        s => s.id === shiftBreak.id,
      );
      this.currentShiftItem!.item.shiftBreak.splice(index!, 1, shiftBreak);
      this.isTakingBreak = false;
    } catch (e) {
      this.isTakingBreak = false;
      throw e;
    }
  }

  @action
  async updateShiftBreak(id: number, body: Dictionary<any>) {
    const response = await this.apiService.put(
      ApiRoutes.shiftBreak.update(id),
      body,
    );
    const shiftBreak: ShiftBreak = response.data;
    const index = this.shiftByDateItem.item.shiftBreak.findIndex(
      obj => obj.id === shiftBreak.id,
    );
    this.shiftByDateItem.item.shiftBreak.splice(index!, 1, shiftBreak);
  }
}
