import NewStore from "./NewStore";
import { TubSheet } from "models/TubSheet";
import { action, computed, observable } from "mobx";
import ApiRoutes from "routes/ApiRoutes";
import { ModelItem } from "models/ModelItem";
import { Bullpen } from "models/Bullpen";
import { PaginatedModelList } from "models/PaginatedModelList";
import { format } from "date-fns";
import { ModelList } from "models/ModelList";
import { Dictionary } from "services/ApiService";

export class TubSheetStore extends NewStore<TubSheet> {
  private static _instance: TubSheetStore;

  @observable tubSheetsList = new PaginatedModelList<TubSheet>(TubSheet);

  @observable tubSheetItem = new ModelItem<TubSheet>(TubSheet);

  @observable selectedTubSheetForAdd = new ModelItem<TubSheet>(TubSheet);

  @observable isPreviousFetching: boolean = false;

  @observable detailPageId: number = 0;

  @observable onGoingBullpenGroups = new ModelList<TubSheet>(TubSheet);

  constructor() {
    super();
    TubSheet._store = this;
    this.searchFilterParam.date = format(new Date(), "yyyy-MM-dd");
  }

  static getInstance(): TubSheetStore {
    if (!this._instance) {
      this._instance = new TubSheetStore();
    }
    return this._instance;
  }

  @computed get tubSheets() {
    return this.tubSheetsList.items;
  }

  @action
  async fetchTubSheets(params?: { [key: string]: any }) {
    return this.tubSheetsList.load(ApiRoutes.tubSheet.fetch, params);
  }

  @action
  async fetchTubSheetById(id: number) {
    return this.tubSheetItem.load(ApiRoutes.tubSheet.show(id));
  }

  @action
  async fetchPreviousTubSheet(id: number) {
    try {
      this.isPreviousFetching = true;
      const bullpenGroupExists = this.onGoingBullpenGroups.items.find(
        el => el.id === id,
      );
      if (!!bullpenGroupExists) {
        this.selectedTubSheetForAdd.setItem(bullpenGroupExists);
        this.selectedTubSheetForAdd.loaded = true;
      } else {
        await this.selectedTubSheetForAdd.load(ApiRoutes.tubSheet.show(id));
      }
    } finally {
      this.isPreviousFetching = false;
    }
  }

  @action
  async updateTubSheetPolling(id: number, body: { [key: string]: any }) {
    const response = await this.apiService.put(
      ApiRoutes.tubSheet.create(id),
      body,
    );
    const index = this.selectedTubSheetForAdd.item?.bullpens.findIndex(
      b => b.id === id,
    );
    if (this.selectedTubSheetForAdd.item) {
      this.selectedTubSheetForAdd.item.bullpens.splice(
        index!,
        1,
        response.data,
      );
    }
  }

  @action
  async addUpcomingTareWeight(bullpen: TubSheet, body: { [key: string]: any }) {
    await this.apiService
      .put(ApiRoutes.tubSheet.show(bullpen.id), {
        upcoming_tare_weight: body.tare_weight,
      })
      .then(() => {
        bullpen.updateFromJson({
          ...bullpen,
          upcoming_tare_weight: body.tare_weight,
        });
      });
  }

  @action
  async tubSheetPolling() {
    if (!this.selectedTubSheetForAdd.item) {
      return;
    }

    let createdAt;

    const { bullpens } = this.selectedTubSheetForAdd.item!;

    if (bullpens.length > 0) {
      createdAt = bullpens[0].created_at;
      bullpens.forEach(bullpen => {
        if (bullpen.created_at > createdAt) {
          createdAt = bullpen.created_at;
        }
      });
    } else {
      createdAt = this.selectedTubSheetForAdd.item!.created_at;
    }

    const response = await this.apiService.get(
      ApiRoutes.tubSheet.polling(this.selectedTubSheetForAdd.item!.id),
      { after: createdAt },
    );

    const bullpen: Bullpen[] = response.data.bullpens;

    this.selectedTubSheetForAdd.item!.upcoming_tare_weight =
      response.data.tare_weight;
    this.selectedTubSheetForAdd.item!.bullpens = [...bullpens, ...bullpen];
  }

  @action
  async moveToCooler(body: { [key: string]: any }) {
    const response = await this.apiService.post(
      ApiRoutes.tubSheet.moveToCooler,
      body,
    );

    const index = this.selectedTubSheetForAdd.item?.bullpens.findIndex(
      b => b.id === body.bullpen_ids[0],
    );
    if (this.selectedTubSheetForAdd.item) {
      this.selectedTubSheetForAdd.item.bullpens.splice(
        index!,
        1,
        response.data.updatedBullpens[0],
      );
    }
  }

  @action
  async fetchSearchedQuery(query?: { [key: string]: any }) {
    this.searchFilterParam = { ...this.searchFilterParam, ...query };
    await this.fetchTubSheets(this.searchFilterParam);
    if (this.searchFilterParam.page) {
      delete this.searchFilterParam.page;
    }
  }

  @action
  async updateElement(
    bullpen: Bullpen,
    tubSheetId: number,
    body: { [key: string]: any },
  ) {
    const response = await this.apiService.put(
      ApiRoutes.tubSheet.create(bullpen.id),
      body,
    );
    const tubSheet = this.get(tubSheetId);
    const index = tubSheet?.bullpens.findIndex(b => b.id === bullpen.id);
    if (tubSheet) {
      tubSheet.bullpens.splice(index!, 1, response.data);
    }
  }

  @action
  async updateBullpenGroup(
    bullpenGroup: TubSheet,
    body: { [key: string]: any },
  ) {
    try {
      bullpenGroup.setUpdating(true);
      const response = await this.apiService.put(
        ApiRoutes.inspections.show(bullpenGroup.id),
        body,
      );
      bullpenGroup.updateFromJson(response.data);
      bullpenGroup.setUpdating(false);
    } catch (e) {
      bullpenGroup.setUpdating(false);
      throw e;
    }
  }

  @action
  async fetchOnGoingBullpenGroups(params?: Dictionary<any>) {
    return this.onGoingBullpenGroups.load(
      ApiRoutes.inspections.ongoing,
      params,
      {
        forceRefresh: true,
      },
    );
  }

  @action
  setDetailPageId(id: number) {
    this.detailPageId = id;
  }

  @action
  getDetailPageId() {
    return this.detailPageId;
  }
}
