import { apiService } from "./ApiService";
import { LoginResponse } from "responses/LoginResponse";
import { User } from "models/UserModel";
import { toast } from "react-toastify";

class AuthService {
  static getInstance(): AuthService {
    return new AuthService();
  }

  get authToken() {
    return localStorage.getItem("auth_token");
  }

  get hasAuthToken() {
    return !!this.authToken;
  }

  async login(email: string, password: string): Promise<User> {
    const response = await apiService.post<LoginResponse>("/login", false, {
      email,
      password,
    });
    localStorage.setItem("auth_token", response.token);
    return response.user;
  }

  async getPreSignedUrl(body: { [key: string]: any }): Promise<string> {
    try {
      let response = { signed_url: "" };
      response = await apiService.post("/signed-url", true, body);
      return response.signed_url;
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  }

  async resetPassword(body: { [key: string]: any }): Promise<any> {
    const response = await apiService.post<LoginResponse>(
      "/reset-password",
      false,
      body,
    );
    localStorage.setItem("auth_token", response.token);
    return response.user;
  }
}

export const authService = AuthService.getInstance();
