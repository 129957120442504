import { PermissionLevel } from "enums/permission.enum";
import { times, random } from "lodash";

export class Utils {
  static getAccessLevelText(level: PermissionLevel): string {
    const levels: string[] = [];

    if (level >= 1) {
      levels.push("View");
    }

    if (level >= 2) {
      levels.push("Create");
    }

    if (level >= 3) {
      levels.push("Manage");
    }
    return levels.length ? `(${levels.join(", ")})` : "";
  }

  static getRandomString = (length = 20) =>
    times(length, () => random(35).toString(36)).join("");

  static responsiveSize = {
    smallMobile: "600px",
    mobile: "700px",
    customTab: "800px",
    tabSize2: "950px",
    tabSize1: "1000px",
    ipadPro: "1024px",
    lg: "1200px",
    xl: "1310px",
  };

  static getObjectKeyByValue(obj, value) {
    return Object.keys(obj).find(key => obj[key] === value);
  }
}