import { action, computed, observable } from "mobx";
import NewStore from "./NewStore";
import { ModelList } from "models/ModelList";
import { CutSize } from "models/CutSize";
import { ModelItem } from "models/ModelItem";
import ApiRoutes from "routes/ApiRoutes";

export class CutSizeStore extends NewStore<CutSize> {
  private static _instance: CutSizeStore;

  constructor() {
    super();
    CutSize._store = this;
  }

  static getInstance(): CutSizeStore {
    if (!this._instance) {
      this._instance = new CutSizeStore();
    }
    return this._instance;
  }

  @observable cutSizeList = new ModelList<CutSize>(CutSize);

  @observable cutSizeItem = new ModelItem<CutSize>(CutSize);

  @computed get cutSizes() {
    return this.cutSizeList.items.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );
  }

  @computed get activeCutSizes() {
    return this.cutSizes.filter(cooler => !cooler.is_archived);
  }

  @computed get inActiveCutSizes() {
    return this.cutSizes.filter(cooler => cooler.is_archived);
  }

  @action
  async fetchCutSizes(params?: { [key: string]: any }) {
    return this.cutSizeList.load(ApiRoutes.cutSizes.list, params);
  }

  @action
  async createElement(name) {
    const response = await this.apiService.post(ApiRoutes.cutSizes.list, {
      name,
    });
    const cooler = CutSize.fromJson(response.data) as CutSize;
    this.cutSizeList.appendItem(cooler);
    return cooler;
  }

  @action
  async updateElement(cooler: CutSize, body: { [key: string]: any }) {
    try {
      cooler.setUpdating(true);
      const response = await this.apiService.put(
        ApiRoutes.cutSizes.show(cooler.id),
        body,
      );
      cooler.setUpdating(false);
      cooler.updateFromJson(response.data);
      return cooler;
    } catch (e) {
      cooler.setUpdating(false);
      throw e;
    }
  }

  @action
  async deleteElement(cooler: CutSize) {
    try {
      cooler.setDeleting(true);
      await this.apiService.delete(ApiRoutes.cutSizes.show(cooler.id));
      cooler.setDeleting(false);
      cooler.delete();
    } catch (e) {
      cooler.setDeleting(false);
      throw e;
    }
  }

  @action
  async fetchCutSizeFromId(id: number) {
    return this.cutSizeItem.load(ApiRoutes.cutSizes.show(id));
  }
}
