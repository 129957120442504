import { action, computed, observable } from "mobx";
import { Boat } from "models/Boat";
import { ModelList } from "models/ModelList";
import NewStore from "./NewStore";
import ApiRoutes from "routes/ApiRoutes";
import { ModelItem } from "models/ModelItem";

export class BoatStore extends NewStore<Boat> {
  private static _instance: BoatStore;

  @observable boatsList = new ModelList<Boat>(Boat);

  @observable boatItem = new ModelItem<Boat>(Boat);

  constructor() {
    super();
    Boat._store = this;
  }

  static getInstance(): BoatStore {
    if (!this._instance) {
      this._instance = new BoatStore();
    }
    return this._instance;
  }

  @computed get boats() {
    return this.boatsList.items.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );
  }

  @computed get boat() {
    return this.boatItem.item;
  }

  @computed get activeBoats() {
    return this.boats.filter(e => !e.is_archived);
  }

  @computed get inActiveBoats() {
    return this.boats.filter(e => e.is_archived);
  }

  @action
  async fetchBoatFromId(id: number) {
    return this.boatItem.load(ApiRoutes.boats.show(id));
  }

  @action
  async fetchBoats(params?: { [key: string]: any }) {
    return this.boatsList.load(ApiRoutes.boats.list, params);
  }

  @action
  async createElement(name: string) {
    const response = await this.apiService.post(ApiRoutes.boats.list, { name });
    const boat = Boat.fromJson(response.data) as Boat;
    this.boatsList.appendItem(boat);
    return boat;
  }

  @action
  async updateElement(boat: Boat, body: { [key: string]: any }) {
    try {
      boat.setUpdating(true);
      const response = await this.apiService.put(
        ApiRoutes.boats.show(boat.id),
        body,
      );
      boat.setUpdating(false);
      boat.updateFromJson(response.data);
      return boat;
    } catch (e) {
      boat.setUpdating(false);
      throw e;
    }
  }

  @action
  async deleteElement(boat: Boat) {
    try {
      boat.setDeleting(true);
      await this.apiService.delete(ApiRoutes.boats.show(boat.id));
      boat.setDeleting(false);
      boat.delete();
    } catch (e) {
      boat.setDeleting(false);
      throw e;
    }
  }
}
