export default {
  shiftType: {
    fetch: "/shift-types",
  },
  shiftBreak: {
    start: "/shift-breaks/start",
    end: "/shift-breaks/end",
    update: (id: number) => `/shift-breaks/${id}`,
  },
  currentShift: {
    fetch: "/shifts/current-shift",
    create: "/shifts/start",
    end: "/shifts/end",
    resume: (id: number) => `/shifts/resume/${id}`,
  },
  shiftByDate: {
    list: "/shifts/shift-by-date",
    dumping: "/shifts/dumpings-shift-by-date",
  },
  designations: {
    permissions: "/designations/permissions", // deprecated
    list: "/designations",
    show: (id: number) => `/designations/${id}`,
  },
  permissions: {
    list: "permissions",
  },
  boats: {
    list: "/boats",
    show: (id: number) => `/boats/${id}`,
  },
  bullpenSizes: {
    list: "/bullpen-sizes",
    show: (id: number) => `/bullpen-sizes/${id}`,
  },
  bullpenWeights: {
    list: "/bullpen-weights",
    show: (id: number) => `/bullpen-weights/${id}`,
  },
  coolers: {
    list: "/coolers",
    getClientCoolers: "/distributions/coolers",
    show: (id: number) => `/coolers/${id}`,
  },
  customers: {
    list: "/customers",
    show: (id: number) => `/customers/${id}`,
  },
  cutSizes: {
    list: "/cut-sizes",
    show: (id: number) => `/cut-sizes/${id}`,
  },
  harvestLocations: {
    list: "/harvest-locations",
    show: (id: number) => `/harvest-locations/${id}`,
  },
  packingSizes: {
    list: "/packing-sizes",
    show: (id: number) => `/packing-sizes/${id}`,
  },
  species: {
    list: "/species",
    show: (id: number) => `/species/${id}`,
  },
  specNumbers: {
    list: "/specifications",
    show: (id: number) => `/specifications/${id}`,
    updateSpecHistoryData: (id: number) =>
      `specifications/${id}/specification-data`,
  },
  juiceSpecNumbers: {
    list: "/juice-specifications",
    show: (id: number) => `/juice-specifications/${id}`,
    updateSpecHistoryData: (id: number) =>
      `juice-specifications/${id}/juice-specification-data`,
  },
  tags: {
    list: "/tags",
    show: (id: number) => `/tags/${id}`,
  },
  users: {
    list: "/users",
    show: (id: number) => `/users/${id}`,
    copyInvite: (id: number) => `/users/${id}/copy-invitation-link`,
    resendInvite: (id: number) => `/users/${id}/resend-invitation`,
    cancelInvite: (id: number) => `/users/${id}/cancel-invitation`,
    reActivateInvite: (id: number) => `/users/${id}/re-activate-invitation`,
  },
  auth: {
    me: "/me",
    changePassword: "/change-password",
    forgotPassword: "/forgot-password",
  },
  shellstocks: {
    list: "/shellstocks",
    show: (id: number) => `/shellstocks/${id}`,
  },
  trailerConditions: {
    options: "/options/trailer-conditions",
  },
  dumpings: {
    list: "/dumpings",
    show: (id: number) => `/dumpings/${id}`,
    getStats: "/dumpings/stats",
    specieCount: "/dumpings/specie-dumping-count",
    pastDumpings: "/pastDumpings",
  },
  inspections: {
    list: "/grind-inspections/groups",
    createGroupForPast: "/grind-inspections/old-groups",
    show: (id: number) => `/grind-inspections/groups/${id}`,
    updateInspection: (id: number) =>
      `/grind-inspections/bullpen-inspection/${id}`,
    listSpecifications: "/specifications/grind-inspection",
    ongoing: "/grind-inspections/groups-by-status",
  },
  juiceInspections: {
    list: "/juice-inspections/groups",
    createGroupForPast: "/juice-inspections/old-groups",
    show: (id: number) => `/juice-inspections/groups/${id}`,
    updateInspection: (id: number) =>
      `/juice-inspections/juice-bullpen-inspection/${id}`,
    listSpecifications: "/juice-specifications",
    ongoing: "/juice-inspections/groups-by-status",
  },
  bullpen: {
    fetchRework: "/grind-inspections/rework-carry-over",
    create: "/grind-inspections",
    createBullpenForPast: "/grind-inspections/old",
    generate: (id: number) => `/grind-inspections/bullpen-number/${id}`,
    generateNumberForPast: "/grind-inspections/old-bullpen-number",
    derework: "/distributions/derework",
  },
  juiceBullpen: {
    create: "/juice-inspections",
    generate: "/juice-inspections/juice-bullpen-number",
    fetchRework: "/juice-inspections/rework-carry-over",
    generateNumberForPast: "/juice-inspections/old-juice-bullpen-number",
    createBullpenForPast: "/juice-inspections/old",
  },
  tubSheet: {
    fetch: "/tub-sheets/groups",
    show: (id: number) => `/tub-sheets/groups/${id}`,
    create: (id: number) => `/tub-sheets/bullpens/${id}`,
    polling: (id: number) => `/bullpens/groups/${id}/polling`,
    moveToCooler: "/bullpens/move-to-cooler",
  },
  juiceTubSheet: {
    fetch: "/juice-tub-sheets/groups",
    show: (id: number) => `/juice-tub-sheets/groups/${id}`,
  },
  approveBullpens: {
    fetch: "/bullpens/waiting-for-approval",
    approve: (id: number) => `/bullpens/${id}/approve`,
    reject: (id: number) => `bullpens/${id}/reject`,
    polling: "/bullpens/waiting-for-approval/polling",
  },
  approveJuiceBullpens: {
    fetch: "/juice-bullpens/waiting-for-approval",
    approve: (id: number) => `/juice-bullpens/${id}/approve`,
    reject: (id: number) => `juice-bullpens/${id}/reject`,
    polling: "/juice-bullpens/waiting-for-approval/polling",
  },
  distribution: {
    list: "/distributions",
    show: (id: number) => `/distributions/${id}`,
    todaysBullpens: "/distributions/todays-production-bullpens",
    listBullpenFromCooler: (id: number) => `/distributions/coolers/${id}`,
    getCustomer: "/distributions/get-customer-and-product",
    rework: "/distributions/rework",
  },
  productSummary: {
    list: "/product-summary-report/past",
    update: (id: number) => `/product-summary-report/${id}`,
    generateReport: "/reports",
  },
  downtimeTypes: {
    list: "/downtime-types",
  },
  goals: {
    list: "/goals-for-tomorrow",
    getByDate: "/goals-for-tomorrow/date",
    getByDates: "goals-for-tomorrow/dates",
    deleteGoal: (id: number) => `/goals-for-tomorrow/${id}`,
  },
  forms: {
    getFormsByDate: "/forms/date",
    sop: {
      createForm: "/forms/sop",
      updateForm: (formId: number) => `/forms/sop/${formId}`,
      listQuestions: "/forms/sop/questions",
      createEntries: (formId: number) => `/forms/sop/${formId}/entries`,
      updateEntries: (entryId: number) => `/forms/sop/entries/${entryId}`,
    },
  },
  reports: {
    getYieldSpreadsheet: "yield-report",
    getYieldList: "yield-report/print",
    getSandSpreadsheet: "sand-report",
  },
  tagOwners: {
    list: "/tag-owners",
    show: (id: number) => `/tag-owners/${id}`,
  },
};
