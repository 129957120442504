import { Dumping } from "models/Dumping";
import { action, computed, observable } from "mobx";
import { Dictionary } from "services/ApiService";
import NewStore from "./NewStore";
import ApiRoutes from "routes/ApiRoutes";
import { ModelItem } from "models/ModelItem";
import { format } from "date-fns";
import { PaginatedModelList } from "models/PaginatedModelList";

export class DumpingStore extends NewStore<Dumping> {
  private static _instance: DumpingStore;

  @observable dumpingList = new PaginatedModelList<Dumping>(Dumping);

  @observable previousDumpItem = new ModelItem<Dumping>(Dumping);

  @observable dumpedBoatId: number | undefined;

  @observable dumpedSpecieId: number | undefined;

  @observable isPreviousFetching: boolean = false;

  @observable dump_stats: { [key: string]: string };

  @observable dump_stats_loaded: boolean = false;

  @observable dump_stats_loading: boolean = false;

  @observable dump_specie_stats: [] = [];

  @observable dump_specie_stats_loading: boolean = false;

  @observable dump_specie_stats_loaded: boolean = false;

  constructor() {
    super();
    Dumping._store = this;
    this.getDumpedBoatId();
    this.getDumpedSpecieId();
    this.searchFilterParam.dump_time = format(new Date(), "yyyy-MM-dd");
  }

  static getInstance(): DumpingStore {
    if (!this._instance) {
      this._instance = new DumpingStore();
    }
    return this._instance;
  }

  @computed get dumpings() {
    return this.dumpingList.items
      .sort(
        (a, b) =>
          new Date(a.dump_time).getTime() - new Date(b.dump_time).getTime(),
      )
      .reverse();
  }

  @computed get previousDump() {
    return this.previousDumpItem.item;
  }

  @action
  async fetchDumpings(params?: Dictionary<any>) {
    return this.dumpingList.load(ApiRoutes.dumpings.list, params);
  }

  @action
  resetDumpStats() {
    this.dump_stats.currentShiftCageCount = "0";
    this.dump_stats.lastHourCageCount = "0";
    this.dump_stats.slackPerBoat = "0";
  }

  @action
  async createElement(body: { [key: string]: any }) {
    this.setDumpedBoatId(body.boat_id);
    this.setDumpedSpecieId(body.specie_id);
    let response;
    if (body.hasOwnProperty("dump_time")) {
      response = await this.apiService.post(ApiRoutes.dumpings.pastDumpings, body);
    } else {
      response = await this.apiService.post(ApiRoutes.dumpings.list, body);
    }
    const dump = Dumping.fromJson(response.data) as Dumping;
    this.previousDumpItem.setItem(dump);
    this.dumpingList.appendItem(dump);
    this.getSpecieDumpings(true);
    this.getDumpingCount(undefined, true);
    return dump;
  }

  @action
  async fetchPreviousDumping() {
    try {
      this.isPreviousFetching = true;
      const response = await this.apiService.get(ApiRoutes.dumpings.list, {
        limit: 1,
      });
      const dump = Dumping.fromJson(response.data[0]) as Dumping;
      this.previousDumpItem.setItem(dump);
    } finally {
      this.isPreviousFetching = false;
    }
  }

  @action
  async fetchSearchedQuery(query?: { [key: string]: any }) {
    this.searchFilterParam = { ...this.searchFilterParam, ...query };
    await this.fetchDumpings(this.searchFilterParam);
    if (this.searchFilterParam.page) {
      delete this.searchFilterParam.page;
    }
  }

  @action
  async updateElement(dump: Dumping, body: { [key: string]: any }, dump_time: string) {
    try {
      dump.setUpdating(true);
      const response = await this.apiService.put(
        ApiRoutes.dumpings.show(dump.id),
        body,
      );
      dump.setUpdating(false);
      dump.updateFromJson(response.data);

      if (body.dump_time.substring(0, 10) !== dump_time) {
        this.dumpingList.removeItem(dump);
      }
      return dump;
    } catch (e) {
      dump.setUpdating(false);
      throw e;
    }
    finally {
      this.getSpecieDumpings(true);
      this.getDumpingCount(undefined, true);
    }
  }

  @action
  async deleteElement(dump: Dumping) {
    try {
      dump.setDeleting(true);
      await this.apiService.delete(ApiRoutes.dumpings.show(dump.id));
      dump.setDeleting(false);
      dump.delete();
    } catch (e) {
      dump.setDeleting(false);
      throw e;
    } finally {
      this.getSpecieDumpings(true);
      this.getDumpingCount(undefined, true);
    }
  }

  @action
  async getDumpingCount(boat_id?: number, force?: boolean) {
    try {
      if (!force && this.dump_stats_loaded) {
        return;
      }
      this.dump_stats_loading = true;

      let response;
      let params = {};

      const boatId = boat_id || localStorage.getItem("dumped_boat_id");
      if (boatId && !!this.searchFilterParam.dump_time) {
        params = {
          boat_id: boatId,
          dump_time: this.searchFilterParam.dump_time,
        };
      } else if (boatId) {
        params = {
          boat_id: boatId,
        };
      }

      response = await this.apiService.get(ApiRoutes.dumpings.getStats, params);
      this.dump_stats = response;
      this.dump_stats_loaded = true;
      this.dump_stats_loading = false;
    } catch (e) {
      this.dump_stats_loaded = true;
      this.dump_stats_loading = false;
      throw e;
    }
  }

  @action
  async getSpecieDumpings(force?: boolean) {
    try {
      if (!force && this.dump_specie_stats_loaded) {
        return;
      }
      this.dump_specie_stats_loading = true;
      if (!!this.searchFilterParam.dump_time) {
        const params = {
          dump_time: this.searchFilterParam.dump_time,
        };
        const response = await this.apiService.get(
          ApiRoutes.dumpings.specieCount,
          params,
        );
        this.dump_specie_stats = response.data.specieDumpings;
      } else {
        this.dump_specie_stats = [];
      }
      this.dump_specie_stats_loaded = true;
      this.dump_specie_stats_loading = false;
    } catch (e) {
      this.dump_specie_stats_loaded = true;
      this.dump_specie_stats_loading = false;
      throw e;
    }
  }

  @action
  setDumpedBoatId(boat_id: number) {
    localStorage.setItem("dumped_boat_id", boat_id.toString());
    this.dumpedBoatId = boat_id;
  }

  @action
  getDumpedBoatId() {
    const boat = localStorage.getItem("dumped_boat_id");
    this.dumpedBoatId = boat === null ? undefined : +boat!;
  }

  @action
  setDumpedSpecieId(specie_id: number) {
    localStorage.setItem("dumped_specie_id", specie_id.toString());
    this.dumpedSpecieId = specie_id;
  }

  @action
  getDumpedSpecieId() {
    const specieId = localStorage.getItem("dumped_specie_id");
    this.dumpedSpecieId = specieId === null ? undefined : +specieId!;
  }
}
