import { action, computed, observable } from "mobx";
import { ModelList } from "models/ModelList";
import { PackingSize } from "models/PackingSize";
import { ModelItem } from "models/ModelItem";
import ApiRoutes from "routes/ApiRoutes";
import NewStore from "./NewStore";

export class PackingSizeStore extends NewStore<PackingSize> {
  private static _instance: PackingSizeStore;

  @observable isFetching: boolean = false;

  @observable isLoading: boolean = false;

  constructor() {
    super();
    PackingSize._store = this;
  }

  static getInstance(): PackingSizeStore {
    if (!this._instance) {
      this._instance = new PackingSizeStore();
    }
    return this._instance;
  }

  @observable packingSizeList = new ModelList<PackingSize>(PackingSize);

  @observable packingSizeItem = new ModelItem<PackingSize>(PackingSize);

  @computed get packingSizes() {
    return this.packingSizeList.items.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );
  }

  @computed get activePackingSizes() {
    return this.packingSizes.filter(packingSize => !packingSize.is_archived);
  }

  @computed get inActivePackingSizes() {
    return this.packingSizes.filter(packingSize => packingSize.is_archived);
  }

  @action
  async fetchPackingSizes(params?: { [key: string]: any }) {
    return this.packingSizeList.load(ApiRoutes.packingSizes.list, params);
  }

  @action
  async createElement(name, type?) {
    const response = await this.apiService.post(ApiRoutes.packingSizes.list, {
      name, type
    });
    const packingSize = PackingSize.fromJson(response.data) as PackingSize;
    this.packingSizeList.appendItem(packingSize);
    return packingSize;
  }

  @action
  async updateElement(packingSize: PackingSize, body: { [key: string]: any }) {
    try {
      packingSize.setUpdating(true);
      const response = await this.apiService.put(
        ApiRoutes.packingSizes.show(packingSize.id),
        body,
      );
      packingSize.setUpdating(false);
      packingSize.updateFromJson(response.data);
      return packingSize;
    } catch (e) {
      packingSize.setUpdating(false);
      throw e;
    }
  }

  @action
  async deleteElement(packingSize: PackingSize) {
    try {
      packingSize.setDeleting(true);
      await this.apiService.delete(ApiRoutes.packingSizes.show(packingSize.id));
      packingSize.setDeleting(false);
      packingSize.delete();
    } catch (e) {
      packingSize.setDeleting(false);
      throw e;
    }
  }

  @action
  async fetchPackingSizeFromId(id: number) {
    return this.packingSizeItem.load(ApiRoutes.packingSizes.show(id));
  }
}
