export const STORE_APPROVE_BULLPENS = "approveBullpensStore";
export const STORE_APPROVE_JUICE_BULLPENS = "approveJuiceBullpenStore";
export const STORE_BOAT = "boatStore";
export const STORE_BULLPEN_SIZE = "bullPenSizeStore";
export const STORE_BULLPEN_WEIGHT = "bullPenWeightStore";
export const STORE_COOLER = "coolerStore";
export const STORE_CUSTOMER = "customerStore";
export const STORE_CUTSIZE = "cutSizeStore";
export const STORE_DESIGNATION = "designationStore";
export const STORE_DISTRIBUTION = "distributionStore";
export const STORE_DOWNTIME = "downtimeStore";
export const STORE_DUMPING = "dumpingStore";
export const STORE_GOALS = "goalsStore";
export const STORE_GRIND_INSPECTION = "grindInspectionStore";
export const STORE_JUICE_INSPECTION = "juiceInspectionStore";
export const STORE_HARVEST_LOCATION = "harvestLocationStore";
export const STORE_PACKING_SIZE = "packingSizeStore";
export const STORE_PERMISSION = "permissionStore";
export const STORE_PRODUCT_SUMMARY = "productSummaryStore";
export const STORE_SHELL_STOCK = "shellStockStore";
export const STORE_SHIFT = "shiftStore";
export const STORE_SPECIE = "specieStore";
export const STORE_SPECIFICATION = "specificationStore";
export const STORE_JUICE_SPECIFICATION = "juiceSpecificationStore";
export const STORE_TAG = "tagStore";
export const STORE_TUBSHEET = "tubSheetStore";
export const STORE_JUICE_BULLPEN = "juiceBullpenStore";
export const STORE_USER = "userStore";
export const STORE_SOPFORM = "sopFormStore";
export const STORE_TAG_OWNER = "tagOwnerStore";
